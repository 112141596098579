import { CircularProgress, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

const style = {
    image:{
        filter: 'grayscale(100%)',
        position: 'absolute', 
        fontSize: 48, 
        marginTop: '-120px', 
        marginLeft: '45px', 
        color: '#fff'
    },
  };

const colors = ['rgba(243,207,61,0.5)', 'rgba(144,144,144,0.5)', 'rgba(168,114,0,0.5)', '#fff', '#fff'];

const Leaderboard = ({ rows }) => {
  const { t } = useTranslation(); 
  return(
    <Grid>
      <h1>{t("dashboard.campaignUser.leaderboards")}</h1>
      {
        rows.map((item, index) => {
          return (
            <Paper sx={{direction: 'row', flex:1, display:'flex', alignContent:'center'}}  style={{ backgroundColor: `${colors[index]}`, marginBottom: '5px', padding: '8px 20px'}}>
              <h3>{index+1}-</h3>&nbsp;
              
              <h3>{item.first_name}</h3>&nbsp; 
              <h3>{item.last_name}</h3>&nbsp;
              <h3>{item.points}&nbsp;{t("dashboard.campaignUser.pts")}</h3>&nbsp; 
            </Paper>
          )
        })
      }
    </Grid>
  );

};

Leaderboard.propTypes = {
  rows: PropTypes.array
};

Leaderboard.defaultProps = {
 rows: []
};

export default Leaderboard;
