import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {  useEffect } from 'react';
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import SearchFilter from '../common/SearchFilter';
import CommonTable from '../common/CommonTable';
import { Chip } from '@mui/material';
import { useState } from 'react';
import { getCall } from '../../helpers/apiManager';
import { CATALOG_VOTERS_ENDPOINT, VOTERS_ENDPOINT } from '../../routes';

import ModalPopover from '../common/ModalPopover';
import Edit from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import VoterForm from '../VoterForm';
import Confetti from 'react-confetti';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const CatalogVoters = () =>{
  const { t, i18n } = useTranslation(); 
  const headersVotersResults = [ 
    "commonTable.header.actions", 
    "commonTable.header.identifier", 
    "commonTable.header.first_name", 
    "commonTable.header.last_name_1", 
    "commonTable.header.last_name_2"
  ];
  const headersMyVoters = [ 
    "commonTable.header.actions", 
    "commonTable.header.status", 
    "commonTable.header.identifier", 
    "commonTable.header.first_name", 
    "commonTable.header.last_name_1", 
    "commonTable.header.last_name_2",
    "commonTable.header.province",
    "commonTable.header.canton", 
    "commonTable.header.phone"
  ];
  const [voters, setVoters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [myVoters, setMyVoters] = useState([]);
  const [points, setPoints] = useState(0);
  const [resultsCount, setResultsCount] = useState (0);
  const [searchMade, setSearchMade] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [voterInfo, setVoterInfo] = useState(
    {
      identifier: "",
      first_name: "",
      last_name_1: "",
      last_name_2: "",
      district: "",
      province: "",
      canton:""
    }
  );
  const [isVoterPopupOpen, setIsVoterPopupOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  
  const prepareEditData = async (value) => {
    
    let data = {...value};
    data.status = value.status.props.label;
    await setVoterInfo(data);
    await setIsEdit(true);
    await setIsVoterPopupOpen(true);
  }

  const fetchVoters = async () => {
    await setIsLoading(true);
    let votersResults = await getCall(VOTERS_ENDPOINT);
    if(votersResults.success){
      votersResults.voters = votersResults.voters.map((elementP) => {
        let element = {...elementP};
        element = getTableHeaders(element);

        let chipColor = 'error';
        let variant = "";
        if  (element.status === 'Confirmado'){
          chipColor = 'success';
        }
        else if (element.status === 'Pendiente'){
          chipColor = 'warning';
          
        }
        else if (element.status === 'Incluido'){
          chipColor = 'success';
          variant = 'outlined';
        }
        element.status = <Chip label={element.status} color={chipColor} variant={variant} />;
        element["commonTable.header.actions"] = (
          <IconButton  
            sx={{border: '1px solid'}} 
            aria-label="edit" 
            color="primary"
            onClick={() => prepareEditData(element)}
          >
            <Edit />
          </IconButton>
        );
        return element;
      });

      setMyVoters(votersResults.voters);
    }
    await setIsLoading(false);
    console.log(votersResults);
  }

  useEffect( () => {
    fetchVoters();
  }, []);


  const openModal = async (data) => {
    await setVoterInfo(data);
    await setIsVoterPopupOpen(true);
  }

  const getTableHeaders = (elementP) => {
    let formatted = {};
    for (let [key, value] of Object.entries(elementP)) {
      formatted[`commonTable.header.${key}`] = value;
    }
    return formatted;
  }

  const addTableElementActions = (elementP) =>{
    let formatted = getTableHeaders(elementP);
    formatted["commonTable.header.actions"] = <Button onClick={() => {setIsEdit(false); openModal(elementP)}}>Agregar</Button>
    //const element = {...elementP, ...{ "Acciones": <Button onClick={() => {setIsEdit(false); openModal(elementP)}}>Agregar</Button>}};
    return formatted;
  }

  const doSearch = async (type, data) => {
    await setIsSearching(true);
    const results = await getCall(CATALOG_VOTERS_ENDPOINT, data, true);
    if(!results.success){
      let errors = "";
      for(let i = 0 ; i < results.errors.length; i++){
        errors += results.errors[i] + "\n";
      }
      alert(errors);
    }
    else{
      let tableElements = results.voters.map((element) => {
        return addTableElementActions(element);
      });
      setSearchMade(true);
      setResultsCount(tableElements.length);
      setVoters(tableElements);
    }
    await setIsSearching(false);
  }

  const renderConfetti = () =>{

    if(points > 0){
      return showConfetti ? ( 
        <>
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            <AlertTitle>¡Felicidades! Has Ganado {points} puntos</AlertTitle>
            Recuerda que ganás puntos por cada votante potencial agregado, cada número telefónico y cada votante potencial verificado.
          </Alert>
          <Confetti
            recycle={false}
            numberOfPieces={500}
            initialVelocityY={5}
            onConfettiComplete={() => setTimeout(() => {
              setShowConfetti(false)
            }, 16000) }
          />
        </>
         ) 
        :
        null;
    }
    else{
      return showConfetti ? ( 
        <>
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="warning">
            <AlertTitle>¡Gracias por agregar un usuario!</AlertTitle>
            Recuerda que ganás puntos por cada votante potencial agregado, cada número telefónico y cada votante potencial verificado.
          </Alert>
        </>
         ) 
        :
        null;
    }



   

  }

  return(
  <AdminLayout>
    <Container component="main" >
      <CssBaseline />
      
      
      <Typography component="h1" variant="h5">
        {t('voters.searchVoters')}
      </Typography>

      <SearchFilter onSubmitCallback={doSearch} isLoading={isSearching} />
    
      <h3 style={{margin: '20px 0 5px 0'}}>{t('voters.searchResults')}</h3>
      {searchMade && <p style={{margin: '20px 0 5px 0'}}>{t('voters.resultsCount')} {resultsCount}</p>}
      
      <CommonTable 
        headers={headersVotersResults} 
        rows={voters} 
      />
      <ModalPopover isOpen={isVoterPopupOpen} onCloseFunction={() => setIsVoterPopupOpen(false)} >
        <VoterForm 
          isEdit={isEdit}
          voterInfo={voterInfo} 
          onSubmitCallback={
            (data)=>{
              const el = [...myVoters, ...[data]];
              if(data.success){
                setPoints(data.points);
                setShowConfetti(true);
              }
              else{
                alert(data.errors);
              }
              //setMyVoters(el);
              fetchVoters();
              setIsVoterPopupOpen(false);
            }
          } 
        />
      </ModalPopover>
      {renderConfetti()}
      <h3 style={{margin: '20px 0 5px 0'}}>Votantes Agregados</h3>
      {!isLoading && 
        <CommonTable 
          headers={headersMyVoters} 
          rows={myVoters} 
        />
      }
    </Container>
  </AdminLayout>
  );
};

export default CatalogVoters;