import { Link } from 'react-router-dom';
// material-ui
import { Grid, Stack, Typography } from '@mui/material';
import { Layout } from '../../layouts/admin/layout';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
// third-party
import ReactApexChart from 'react-apexcharts';
import { DASHBOARDS_ENDPOINT } from '../../routes';
import { getCall } from '../../helpers/apiManager';
import useAuth from '../../hooks/useAuth';
import { 
  APPLICATION_SUPER_ADMIN,
  PRODUCT_ADMIN,
   CAMPAIGN_ADMIN,
   CAMPAIGN_USER,
   CALL_CENTER_USER
} from '../../helpers/roles';
import AdminDashboard from './AdminDashboard';
import CampaignUserDashboard from './CampaignUserDashboard';
import CallCenterDashboard from './CallCenterDashboard';


const Dashboard = () => {
  const {getLoggedUserAttr} = useAuth();
  
  const [role, setRole] = useState();

  const theme = useTheme();
  useEffect(() => {
    const getRole = async () => await setRole(await getLoggedUserAttr("role_id"));

    getRole();
  }, []);

  const renderCampaignUserDashboard = () => {
    return <CampaignUserDashboard />
  }

  const renderCallCenterDashboard = () => {
    return <CallCenterDashboard />
  }

  const renderCampaignAdminDashboard = () => {
    return <AdminDashboard />
  }

  const render = () => {
    if(role === CAMPAIGN_USER){
      return renderCampaignUserDashboard();
    }
    else if (role === CAMPAIGN_ADMIN){
      return renderCampaignAdminDashboard();
    }
    else if (role === CALL_CENTER_USER) {
      return renderCallCenterDashboard();
    }
    else if(role === PRODUCT_ADMIN || role === APPLICATION_SUPER_ADMIN){
      return <h1>SUPER ADMIN DASHBOARD - COMING SOON!</h1>
    }
  }

    return(
      <Layout>     
        {render()}
      </Layout>
    )
    
};
  
  export default Dashboard;