
import {
  Box,
  Divider,
  Drawer,
  Stack,
  useMediaQuery
} from '@mui/material';
import { useEffect } from 'react';
import { Scrollbar } from '../../components/common/scrollbar';
import { SideNavItem } from './SideNavItem';
import { getNavBarItems } from '../../helpers/roles';
import useAuth from '../../hooks/useAuth';
import { useState } from 'react';
import {
  Avatar
} from '@mui/material';
import { useTranslation } from 'react-i18next';
export const SideNav = (props) => {
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const {getLoggedUserAttr} = useAuth();
  const [items, setItems] = useState();
  const [logo, setLogo] = useState( require(`./images/logo.jpeg`));
  const [candidateImage, setCandidateImage] = useState( require(`./images/logo.jpeg`));
  const { t } = useTranslation();

  useEffect(
  () => {

    const getItems = async () => {
      const navItems = getNavBarItems(await getLoggedUserAttr("role_id"));
      const politicalPartyLogo = (await getLoggedUserAttr("political_party_logo"));
      const candidateImage = (await getLoggedUserAttr("candidate_image"));
      if(politicalPartyLogo !== undefined)
        setLogo(politicalPartyLogo);
      if(candidateImage !== undefined)
        setCandidateImage(candidateImage);
      setItems (navItems);
    }

    getItems();

  }, []);

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%'
        },
        '& .simplebar-scrollbar:before': {
          background: 'neutral.400'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            alignItems: 'space-between',
            justifyItems: 'center'
            
          }}
          xs={12}
        >
          <Box xs={6} sx={{ p: 6 }}>
            <Box
              href="/"
              sx={{
                display: 'inline-flex',
                height: 32,
                width: 32,
                marginRight: -1
              }}
            >
              <Avatar
                src={candidateImage}
                sx={{
                  height: 80 ,
                  mb: 2,
                  width: 80               
                }}
              />
            </Box>
          </Box>
          <Box sx={{ p: 6 }}>
            <Box
              href="/"
              sx={{
                display: 'inline-flex',
                height: 32,
                width: 32
              }}
            >
              <img src={logo} style={{width:'80px', height: '80px'}} />
            {/*   <Avatar
                src={logo}
                sx={{
                  height: 60 ,
                  mb: 2,
                  width: 60
                }}
              />*/}
            </Box>
          </Box>


        </Box>

        <Divider sx={{ borderColor: 'neutral.700' }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0
            }}
          >
            {items && items.map((item) => {
              const pathname = window.location.pathname;
              const active = /*item.path ?*/ (pathname === item.path) ?  true : false;
              return (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={t(item.title)}
                />
              );
            })}
          </Stack>
        </Box>
        <Divider sx={{ borderColor: 'neutral.700' }} />
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.800',
            color: 'common.white',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.800',
          color: 'common.white',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};


