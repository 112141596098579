import * as React from 'react';

import PropTypes from 'prop-types';
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import VoterForm from '../VoterForm';

const Voter = ({identifier, first_name, last_name_1, last_name_2}) =>{
  return(
  <AdminLayout>
    <VoterForm></VoterForm>
  </AdminLayout>
  );
};


Voter.propTypes = {
  identifier: PropTypes.string,
  first_name: PropTypes.string,
  last_name_1: PropTypes.string,
  last_name_2: PropTypes.string
};

Voter.defaultProps = {
  identifier: "",
  first_name: "",
  last_name_1: "",
  last_name_2: "",
};

export default Voter;