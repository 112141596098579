
import { useTheme } from '@mui/material/styles';

import OutlinedInput from '@mui/material/OutlinedInput';


import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import {  useEffect } from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { isEmpty } from 'lodash';

import  { SelectChangeEvent } from '@mui/material/Select';
import { jsx } from '@emotion/react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 80;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




export default function Dropdown({values,label, defaultValue, onChange}) {
  
  const theme = useTheme();
  const [value, setValue] = React.useState([]);

  const getItems = () =>{
    const jsxEl = values.map((item, index) =>
      (<MenuItem index={label+index} value={item.value}>{item.label}</MenuItem>)
    );
    return jsxEl;
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    if(onChange !== null){
      onChange(value);
    }
  };

  useEffect(() => {
    if(!isEmpty(defaultValue)){
      setValue(defaultValue);
    }
   }, [defaultValue]);


  return (
    <Box sx={{ minWidth: 120, marginTop: 3 }} >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId={label}
          id='${label}_id'
          value={value }
          label={label}
          onChange={handleChange}
        > 
          {getItems()}
        </Select>
      </FormControl>
    </Box>
  );
}

Dropdown.propTypes = {
  children: PropTypes.node,
  values: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange:PropTypes.func
};

Dropdown.defaultProps = {
    values: [
      {"value": "Confirmado", "label":"success"},
      {"value": "Pendiente", "label":"warning"},
      {"value": "Negado", "label":"error"},
    ],
    label: 'estado',
    onChange: null,
    defaultValue: null
};