import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Confirmado',
  'Pendiente',
  'Negado',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ChipDropdown({values,label, defaultValue, onChange, isMultiple}) {
  const theme = useTheme();
  const [value, setValue] = React.useState([]);
  useEffect( () => {
    if(defaultValue){
      setValue(
        typeof defaultValue === 'string' ? defaultValue.split(',') : defaultValue,
      );
    }
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    if(onChange !== null){
      onChange(value);
    }
  };

  return (
    <div>
      <FormControl sx={{  width: 300 }}>
        <InputLabel id="chipDropdown">{label}</InputLabel>
        <Select
          multiple={isMultiple}
          labelId="chipDropdownLabel"
          variant="standard"
          id="dropdownChip"
          value={value || defaultValue}
          defaultValue={defaultValue}
          onChange={handleChange}
          input={<OutlinedInput id="chipDropdown" label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.map((value, key) => {
                  //debugger;
                  return <Chip key={value} label={value} />
                })
              }
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {values.map((element) => (
            <MenuItem
              key={element.value}
              value={element.label}
              style={getStyles(element.value, value, theme)}
            >
              {element.label || element.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

ChipDropdown.propTypes = {
    children: PropTypes.node,
    values: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange:PropTypes.func.isRequired,
    isMultiple: PropTypes.bool
};

ChipDropdown.defaultProps = {
    values: [
        {"label": "Confirmado", "value": "Confirmado", "color":"success"},
        {"label": "Pendiente","value": "Pendiente", "color":"warning"},
        {"label": "Negado", "value": "Negado", "color":"error"},
    ],
    label: 'estado',
    onChange: null,
    isMultiple: false
};