import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


import { AuthLayout  } from '../../layouts/auth/AuthLayout';
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import CommonTable from '../common/CommonTable';
import { Chip } from '@mui/material';
import Voter from '../Voter';
import SearchFilter from '../common/SearchFilter';
import { useEffect, useState } from 'react';



const DataEntry = () =>{

  

  // Field Generators
  const generateTextField = (name, id, value = "") =>(
    <TextField
      variant="standard"
      required
      fullWidth
      name={name}
      id={id}
      value={value}
    />
  );



  const headers = [ "Acciones", "Cedula", "Nombre", "Apellido 1", "Apellido 2", "Teléfono", "Email", "Voto Posible"];
  const headers3 = [ "Acciones", "Cedula", "Nombre", "Apellido 1", "Apellido 2"];
  //Ejemplo 1
  const rows = [
    {
      "Cedula" : "1-2345-6789",
      "Nombre" :  "Jose",
      "Apellido 1" :  "Ciccio",
      "Apellido 2" :  "C",
      "Teléfono" : generateTextField("Teléfono", "Teléfono", ""),
      "Email" : generateTextField("Email", "Email", ""),
      "Voto Posible": <Chip label="Confirmado" color="success" />,
      "Acciones": <Button>Guardar</Button>
    },
    {
      "Cedula" : "1-2345-6789",
      "Nombre" :  "Maria",
      "Apellido 1" :  "Bailo",
      "Apellido 2" :  "Yo",
      "Teléfono" : generateTextField("Teléfono", "Teléfono", ""),
      "Email" : generateTextField("Email", "Email", ""),
      "Voto Posible": <Chip label="Pendiente" color="warning" />,
      "Acciones": <Button>Guardar</Button>
    },
    {
      "Cedula" : "1-2345-6789",
      "Nombre" :  "Andrés",
      "Apellido 1" :  "Bailo",
      "Apellido 2" :  "Yo",
      "Teléfono" : generateTextField("Teléfono", "Teléfono", ""),
      "Email" : generateTextField("Email", "Email", ""),
      "Voto Posible": <Chip label="Negado" color="error" />,
      "Acciones": <Button>Guardar</Button>
    },
  ];

  //Ejemplo 2
  let rows2 = [
    {
      "Cedula" : "1-2345-6789",
      "Nombre" :  "Aquiles",
      "Apellido 1" :  "Bailo",
      "Apellido 2" :  "Yo",
      "Teléfono" : "8888-8888",
      "Email" : "email@mail.com",
      "Voto Posible": <Chip label="Confirmado" color="success" />,
      "Acciones": <Button>Editar</Button>
    },
    {
      "Cedula" : "1-2345-6789",
      "Nombre" :  "Karina",
      "Apellido 1" :  "Madrigal",
      "Apellido 2" :  "Yo",
      "Teléfono" : "8888-8888",
      "Email" : "email@mail.com",
      "Voto Posible": <Chip label="Pendiente" color="warning" />,
      "Acciones": <Button>Editar</Button>
    },
    {
      "Cedula" : "1-2345-6789",
      "Nombre" :  "Adrian",
      "Apellido 1" :  "ROjas",
      "Apellido 2" :  "Yo",
      "Teléfono" : "",
      "Email" : "",
      "Voto Posible": <Chip label="Negado" color="error" />,
      "Acciones": <Button  
        href='/admin/voter'
       /* onClick={() => {
          alert('clicked');

        }}*/>Editar
      </Button>
    },
  ];

   //Ejemplo 3
   const rows3 = [
    {
      "Cedula" : "1-2345-6789",
      "Nombre" :  "Aquiles",
      "Apellido 1" :  "Bailo",
      "Apellido 2" :  "Yo",
      "Teléfono" : "8888-8888",
      "Email" : "email@mail.com",
      "Voto Posible": <Chip label="Confirmado" color="success" />,
      "Acciones": <Button>Agregar</Button>
    },
    {
      "Cedula" : "1-2345-6789",
      "Nombre" :  "Aquiles",
      "Apellido 1" :  "Bailo",
      "Apellido 2" :  "Yo",
      "Teléfono" : "",
      "Email" : "",
      "Voto Posible": <Chip label="Pendiente" color="warning" />,
      "Acciones": <Button  
        //href='/admin/voter'
        onClick={(element) => {
          alert('clicked');
          let newElement = rows3.slice(1);
          newElement[0]["Acciones"] = (<Button href='/admin/voter'>Editar</Button>);
          const newRows = addedRows.concat(newElement);//{...addedRows, ...newElement}; 
          
          setAddedRows(newRows);
        }}>Agregar
      </Button>
    },
  ];


  const [addedRows, setAddedRows] = useState(rows2); 

  const onSubmitSearch = (searchType, event) => {
    console.log('onSubmitSearch');
    console.log(searchType);
    console.log(event);
    console.log('- - - -');
  }

  const onSearchChange = (event) => {
    console.log('onSearchChange');
    console.log(event);
    console.log('- - - -');
  }


  return(
    <AdminLayout>
      <pre>
        Pagina de ingreso de datos
      </pre>
      <SearchFilter 
      
        onSubmitCallback={onSubmitSearch}
        onSearchByChange={onSearchChange}
      
      />
      

{/*
      <pre>
        Ejemplo 1 - Cómo modificar datos inline.
        <br />
        Desventaja en dispositivos móviles porque hay que hacer scrolling horizontal
      </pre>
      <CommonTable 
        headers={headers} 
        rows={rows} 
      />

      <pre>
        Ejemplo 2 - Modificar datos utilizando un botón.
        <br />
        Hay una página extra de modificación de datos
      </pre>
      <CommonTable 
        headers={headers} 
        rows={rows2} 
      />


      <pre>
        Ejemplo 3 - Página para buscar y agregar datos
        <br />
        Se requiere un componente de búsqueda, una tabla que despliega resultados y otra extra para mostrar 
        <br />
        los votantes o personas que se han ido agregando.
        <br />
        Se puede considerar un filtro a la tabla de estado si esta comienza a crecer mucho.
      </pre>
      <SearchFilter />
      <h3 style={{margin: '20px 0 5px 0'}}>Resultados búsqueda</h3>
      <CommonTable 
        headers={headers3} 
        rows={rows3} 
      />
      <h3 style={{margin: '20px 0 5px 0'}}>Personas modificadas</h3>
      <CommonTable 
        headers={headers} 
        rows={addedRows} 
      />
  */}



    </AdminLayout>
  )
 
};

export default DataEntry;