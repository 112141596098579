import * as React from 'react';
import Avatar from '@mui/material/Avatar';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Modal } from '@mui/material';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';


const style = {
  position: 'absolute',
  overflow:'scroll',
  display:'block',
  top: '50%',
  left: '50%',
  height:'95%',
  transform: 'translate(-50%, -50%)',
  /*width: 400,*/
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const styles = theme => ({
  modalStyle1:{
    position:'absolute',
    top:'10%',
    left:'80%',

  }
});

const ModalPopover = ({isOpen, onCloseFunction, children}) =>{
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onCloseFunction(false);
  };

    
  return(
    <>
      <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Box sx={{ position: 'absolute', left:'90%', zIndex: 5}}>
            <IconButton 
              sx={{border: '2px solid'}} 
              aria-label="edit" 
              color="primary"
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Modal>
    </>
  );
};


ModalPopover.propTypes = {
  isOpen: PropTypes.bool,
  onCloseFunction: PropTypes.func.isRequired,
};

ModalPopover.defaultProps = {
  isOpen: false,
};

export default ModalPopover;