import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from './Copyright';
import { Grid } from '@mui/material';
import LanguageSelector from './LanguageSelector';


export default function Footer() {
  return (
   
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center'
        }}
      >
        <CssBaseline />
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <>
            <Grid  container sx={{gap: 1,alignContent: 'center', alignItems:'center', justifyContent:'center', paddingRight: 4}}>
              <Grid item container sx={{justifyContent:'flex-end', alignItems:'flex-end'}}>
                <LanguageSelector />
              </Grid>
              <Grid item container sx={{justifyContent:'flex-end', alignItems:'flex-end'}}>
                <Typography variant="body1"  sx={{
                fontSize: '14px',
                fontFamily:'Poppins', 
              }}>
                  Politicapp
                </Typography>
              </Grid>
              <Grid item container sx={{justifyContent:'flex-end', alignItems:'flex-end'}}> 
                <Copyright />
              </Grid>
            </Grid>
            
            
          </>
        </Box>
      </Box>

  );
}