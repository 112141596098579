// PRODUCTION
//export const ROOT_URL = `http://ec2-18-234-57-223.compute-1.amazonaws.com:3000/`;
//export const ROOT_WS = `ws://ec2-18-234-57-223.compute-1.amazonaws.com:3000`;

export const ROOT_URL = `https://api.politicapp.org`;
export const ROOT_WS = 'wss://127.0.0.1:3000'; //'wss://api.politicapp.org:3000';

// DEVELOPMENT
//export const ROOT_URL = `http://127.0.0.1:3000`;
//export const ROOT_WS = `ws://127.0.0.1:3000`;
//export const ROOT_URL = `http://192.168.50.157:3000`;
