import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useForm } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import {  useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { USERS_ENDPOINT } from '../../routes';
import CommonTable from '../common/CommonTable';
import { useState } from 'react';
import { getCall } from '../../helpers/apiManager';
import ModalPopover from '../common/ModalPopover';

import Edit from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import Registration from './Registration';
import { roles } from '../common/Constants';
const ManageRegistration = () =>{
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { logout} = useAuth();
  const navigate = useNavigate();
  const [headers, setHeaders] = useState(["Acciones", "Nombre", "Email", "Usuario Activo", "Rol"]);
  const [rows, setRows] = useState([]);
  const [editData, setEditData] = useState({});
  const [alertMessage, setAlertMessage] = useState("");

  const form = useForm({
    defaultValues: {
      party_name: "",
      name: "",
      representative: "",
      primary_color: "",
      secondary_color: ""
    },
  })

  const { 
    handleSubmit, 
    register,
    formState,
    watch
  } = form;

  const prepareEditData = async (value) => {
    await setEditData(value);
    await setIsPopupOpen(true);
  }

  const fetchUsers = async () =>{
    const usersR = await getCall(USERS_ENDPOINT);
    if(!usersR.success && usersR.login_redirect){
      logout();
    }

    let data = [];
    if(usersR.users !== null){
      data = usersR.users.map((value) => {
        return { 
          "Nombre" : value.full_name , 
          "Email": value.email, 
          "Usuario Activo": value.active ? "Si" : "No",//value.secondary_color,
          "Rol": roles[""+value.role_id],
          "Acciones": (
          <IconButton  
            sx={{border: '1px solid'}} 
            aria-label="edit" 
            color="primary"
            onClick={() => prepareEditData(value)}
          >
            <Edit />
          </IconButton>)
            //<a href="#" onClick={ () => prepareEditData(value)}>Editar</a>
        } 
      });
    }
    setRows(data);
  }

    useEffect( () => {
      fetchUsers(); 
    }, []);

  return(
    <>
      <Container component="main">
        <CssBaseline />
        {
        !isEmpty(alertMessage) && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          <AlertTitle>{alertMessage}</AlertTitle>
        </Alert>
        }
        <Grid  xs={12} container item alignItems="flex-start" justifyContent="flex-start">
          <Button    
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="primary"
            size="large"
            onClick={()=> { 
              setEditData(null);  
              setIsPopupOpen(true); 
            }}
          >
            Agregar
          </Button>
        </Grid>

        <CommonTable 
          headers={headers} 
          rows={rows} 
        />

        <ModalPopover isOpen={isPopupOpen} onCloseFunction={() => setIsPopupOpen(false)} >
          <Registration 
            onSuccess={(text)=> {
              setAlertMessage(text);
              fetchUsers(); 
              setIsPopupOpen(false); 
              setTimeout(() => {
                setAlertMessage("");
              }, 20000)
            }}
            editData={editData}
          />
        </ModalPopover>
    </Container>
    </>
  )
};

export default ManageRegistration;