// src/hooks/useAuth.jsx

import { createContext, useState, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import * as React from "react";

export const useAuth = () => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();


  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Check if user is logged in by looking at localStorage
    return localStorage.getItem('isLoggedIn') === 'true';
  });

  const login = (data) => {
    // Perform login actions
    // For simplicity, let's assume successful login
    // debugger;
    setIsLoggedIn(true);
    // Store login state in localStorage
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('user', data.user);
    setUser(data.user)
    navigate("/");
  };

  const logout = async () => {
    // Perform logout actions
    await setIsLoggedIn(false);
    // Remove login state from localStorage
    await setUser(null);
    await localStorage.removeItem('isLoggedIn');
    await localStorage.removeItem('user');
    navigate("/log-in");
  };

  const getLoggedUserAttr = async (attr) => {
    const userLS = localStorage.getItem('user');
    if(isLoggedIn && isJsonString(userLS)){
      const user = JSON.parse(userLS);
      return user[attr];
    }
    else{
      await logout();
      return null;
    }
  }

  const getLoggedUserAttrSync = (attr) => {
    const userLS = localStorage.getItem('user');
    if(isLoggedIn && isJsonString(userLS)){
      const user = JSON.parse(userLS);
      return user[attr];
    }
    else{
      return null;
    }
  }

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
}


  return { isLoggedIn, getLoggedUserAttr, login, logout };
};

export default useAuth;