import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ChipDropdown from '../common/ChipDropdown';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form"
import { postCall, putCall } from '../../helpers/apiManager';
import { VOTERS_ENDPOINT } from '../../routes';
import { isEmpty } from 'lodash';
import InputMask from 'react-input-mask';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const VoterForm = ({voterInfo, onSubmitCallback, isEdit}) =>{
  const { t, i18n } = useTranslation(); 
  const [phone, setPhone] = React.useState(voterInfo.phone);

  const [voteIntention, setVoteIntention] = React.useState('Si');
  console.log('En Voter Form');
  console.log(voterInfo);
  const form = useForm({
    defaultValues: {
      id: voterInfo.id,
      first_name: voterInfo.first_name,
      last_name_1: voterInfo.last_name_1,
      last_name_2: voterInfo.last_name_2,
      identifier: voterInfo.identifier,
      district: voterInfo.district,
      province: voterInfo.province,
      canton: voterInfo.canton,
      phone:voterInfo.phone,
      status: voterInfo.status,
      gender: voterInfo.gender,
      voteIntention: (isEmpty(voterInfo.status) || 
                      voterInfo.status === "Pendiente" || 
                      voterInfo.status === "Incluido") ? "Si" : "No"
    },
  })
  
  const { 
    handleSubmit, 
    register,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors
  } = form;

  const {errors} = formState;

  useEffect( () => {
    console.log('Use Effect')
    if(!isEmpty(voterInfo)){
      for (let key in voterInfo) {
        let newKey = key.split('.');
        newKey = newKey[newKey.length-1]
        setValue(newKey, voterInfo[key]);
        if (newKey === 'phone'){
          setPhone(voterInfo[key]);
        }
        console.log("Setting: " + key + " => "+ voterInfo[key]);
      }
      
    }
  }, [voterInfo]);


  useEffect(() => {
    const status = getValues('status');
    if(isEdit){
      if(voterInfo.status === "Pendiente" || voterInfo.status === "Incluido"){
        setVoteIntention('Si');
      }
      else {
        setVoteIntention('No');
      }
    }
    // eslint-disable-next-line
  }, [isEdit]);

  const onSubmit = async (data) => {
    if(phone !== undefined){
      const isValid = matchIsValidTel(phone, {});
      if(isValid || isEmpty(phone)){
        data.phone = phone;
        clearErrors('phone');
      }
      else{
        setError('phone', { type: 'custom', message: 'El teléfono no es válido.' });
        return;
      }
    }
    else{
      data.phone = "";
    }
    let results = null;
    if(data.voteIntention === "Si"){
      if(isEmpty(data.phone)){
        data.status = "Pendiente";
      }
      else{
        data.status = "Incluido";
      }
    }
    else {
      data.status = "Negado";
    }
    if(isEdit){
      results = await putCall(`${VOTERS_ENDPOINT}/${data.id}` , data);
    }
    else{
      results = await postCall(VOTERS_ENDPOINT, data, true);
    }
    if(onSubmitCallback)
      onSubmitCallback({...data, ...results});
  };
    
  return(

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >          
        <Typography component="h1" variant="h5">
            {t("voterForm.title")}
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                  margin="normal"
                  fullWidth
                  id="identifier"
                  label={t("common.textfield.id")}
                  name="identifier"
                  autoComplete="identifier"
                  autoFocus
                  disabled
                  {...register("identifier", { required: "Identifier is required." })}
                  error={!!errors.identifier}
                  helperText={errors.identifier?.message}
                  style={{backgroundColor:"#fff"}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                id="first_name"
                label={t("common.textfield.firstName")}
                name="first_name"
                autoComplete="first_name"
                autoFocus
                disabled
                {...register("first_name", { required: "First Name is required." })}
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
                style={{backgroundColor:"#fff"}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                id="last_name_1"
                label={t("common.textfield.lastName1")}
                name="last_name_1"
                autoComplete="last_name_1"
                autoFocus
                disabled
                {...register("last_name_1", { required: "Last Name 1 is required." })}
                error={!!errors.last_name_1}
                helperText={errors.last_name_1?.message}
                style={{backgroundColor:"#fff"}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                id="last_name_2"
                label={t("common.textfield.lastName2")}
                name="last_name_2"
                autoComplete="last_name_2"
                autoFocus
                disabled
                {...register("last_name_2", { required: "Last Name 2 is required." })}
                error={!!errors.last_name_2}
                helperText={errors.last_name_2?.message}
                style={{backgroundColor:"#fff"}}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                id="province"
                label={t("common.textfield.province")}
                name="province"
                autoComplete="province"
                autoFocus
                disabled
                {...register("province", { required: "Province is required." })}
                error={!!errors.province}
                helperText={errors.province?.message}
                style={{backgroundColor:"#fff"}}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                id="canton"
                label={t("common.textfield.canton")}
                name="canton"
                autoComplete="canton"
                autoFocus
                disabled
                {...register("canton", { required: "Canton is required." })}
                error={!!errors.canton}
                helperText={errors.canton?.message}
                style={{backgroundColor:"#fff"}}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                id="district"
                label={t("common.textfield.district")}
                name="district"
                autoComplete="district"
                autoFocus
                disabled
                {...register("district", { required: "District is required." })}
                error={!!errors.district}
                helperText={errors.district?.message}
                style={{backgroundColor:"#fff"}}
              />
            </Grid>
            
            <Grid item xs={12}>
              <MuiTelInput 
                id="phone"
                label={t("common.textfield.phone")}
                name="phone"
                margin="normal"
                fullWidth
                autoComplete='off'
                autoFocus
                forceCallingCode={isEdit}
                {...register("phone")}
                value={phone}//{phone} 
                defaultCountry='CR'
                error={!!errors.phone}
                helperText={errors.phone?.message}
                onChange={(val) =>
                  { 
                    setValue('phone', val);
                    setPhone(val);
                  }
                } 
              />
            </Grid>
            <Grid item xs={12}>
              <ChipDropdown 
                label={t('voterForm.voteIntention')}
                values={[
                  {"label": "Si", "value": true},
                  {"label": "No","value": false}
                ]}
                defaultValue={(
                    isEmpty(voterInfo.status) || 
                    voterInfo.status === "Pendiente" || 
                    voterInfo.status === "Incluido") ? "Si" : "No"
                }
                onChange={(el)=> {
                  setValue('voteIntention', el);   
                }} 
              />
            </Grid>
           
            <Grid item xs={12}>
              <ChipDropdown 
                label={t('voterForm.gender')}
                values={[
                  {"label": "Masculino", "value": "M"},
                  {"label": "Femenino","value": "F"},
                  {"label": "No Definido","value": "N/A"}
              ]}
                defaultValue={voterInfo.gender}
                onChange={(el)=> {setValue('gender', el)}} 
              />
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {t('common.button.save')}
          </Button>
        </Box>
      </Box>
    </Container>

  );
};



VoterForm.defaultProps = {
  voterInfo:{
    identifier: "111561615",
    first_name: "",
    last_name_1: "",
    last_name_2: "",
    id: 0,
    district: "",
    province:"",
    canton:""
  },
  isEdit: false
};

export default VoterForm;