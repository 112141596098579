import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {  useEffect } from 'react';
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import { useState } from 'react';
import { getCall, putCall } from '../../helpers/apiManager';
import { CALL_CENTER_ENDPOINT } from '../../routes';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import ChipDropdown from '../common/ChipDropdown';
import { useForm } from "react-hook-form";
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import CircularLoader from '../common/CircularLoader';

const ConfirmVoters = () =>{
  
  const form = useForm({
    defaultValues: {
      call_center_confirmed: '',
      campaign_help_type: '',
      id: 0
    },
  })
  
  const { 
    handleSubmit, 
    setValue,
    getValues
  } = form;

  const [isLoading, setIsLoading] = useState(false);
  const [nextVoter, setNextVoter] = useState([]);
  const [countLeft, setCountLeft] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [intention, setIntention] = useState("");
  const [isHelping, setIsHelping] = useState("");
  
  const onSubmit = async  (data) => {
    let results = await putCall(`${CALL_CENTER_ENDPOINT}/${data.id}` , data);
    if(!results.success){
      setAlertMessage(results.message);    
      setTimeout(() => {
        setAlertMessage("");
      }, 20000);
    }
    else{
      const name = nextVoter?.info?.full_name;
      setAlertMessage(`Votante ${name} actualizado exitosamente`);    
      setTimeout(() => {
        setAlertMessage("");
      }, 20000);
    }
    fetchVoters();
    console.log(results);
  }

  const fetchVoters = async () => {
    await setIsLoading(true);
    let votersResults = await getCall(CALL_CENTER_ENDPOINT);
    if(votersResults.success){
      const nextVoter = votersResults.next_voter;

      if(nextVoter.count_left > 0){
        setNextVoter(votersResults.next_voter);
        setValue('id', votersResults.next_voter.voter.id);
        setValue('call_center_confirmed', '');
        setValue('campaign_help_type', '');
        setIntention("");
        setIsHelping("")
      }
      setCountLeft(nextVoter.count_left);
    }
    await setIsLoading(false);
    console.log(votersResults);
  }

  useEffect( () => {
    fetchVoters();
  }, []);

  useEffect( () => {
    fetchVoters();
  }, []);

  const renderUpToDate = () =>(
    <Typography component="h3" variant="body2" sx={{marginBottom: 3}}>
      No hay potenciales votantes que confirmar, se encuentra al día.
    </Typography>
  )

  const renderNextVoter = () =>(
    <>
      <Typography component="h3" variant="body1" sx={{marginBottom: 3}}>
        Nombre Completo: {nextVoter?.info?.full_name}
      </Typography>

      <Typography component="h4" variant="body1" sx={{marginBottom: 3}}>
        Número de teléfono&nbsp;{(nextVoter?.voter?.phone)}
      </Typography>
 
      <FormControl >
        <Box component="form"  onSubmit={handleSubmit(onSubmit)}  noValidate sx={{ mt: 1, marginBottom: 3 }}>
          <Grid item xs={12} sx={{ mt: 1, marginBottom: 3 }}>
            <ChipDropdown 
              label="Confirmación de intención"
              values={[
                {"label": "Confirmado", "value": 'Confirmado'},
                {"label": "Pendiente","value": 'Pendiente'},
                {"label": "Negado","value": 'Negado'}
              ]}
              defaultValue={nextVoter.status}
              onChange={(el)=> {
                setValue('call_center_confirmed', el);  
                setIntention(el); 
              }} 
            />
          </Grid>
          {
            intention === "Confirmado" &&
            ( 
              <Grid item xs={12} sx={{ mt: 1, marginBottom: 3 }}>
                <ChipDropdown 
                  label="Desea ayudar en campaña?"
                  values={[
                    {"label": "Si", "value": 'Si'},
                    {"label": "No","value": 'No'}
                  ]}
                  onChange={(el)=> {
                    setIsHelping(el);   
                  }} 
                />
              </Grid>
            )
          }
          {
            isHelping === "Si" &&
            ( 
              <Grid item xs={12} sx={{ mt: 1, marginBottom: 3 }}>
                <ChipDropdown 
                  label="Tipo de ayuda en campaña"
                  values={[
                    {"label": "Guías", "value": 'Confirmado'},
                    {"label": "Miembros de mesa","value": 'miembro_de_mesa'},
                    {"label": "Fiscal","value": 'Fiscal'},
                    {"label": "Transporte","value": 'Transporte'}
                  ]}
                  onChange={(el)=> {
                    setValue('campaign_help_type', el);   
                  }} 
                />
              </Grid>
            )
          }

          <Grid item xs={6}>
            <Button type="submit" variant='contained' >Confirmar y Guardar</Button>
          </Grid>
        </Box>
      </FormControl> 
    </>
  );

  return(
  <AdminLayout>
    <Container component="main" >
    <Card variant="outlined" sx={{padding:'20px 20px', backgroundColor: 'rgba(239, 239, 239, 0.2)'}}>
      <CssBaseline />
      
      <Typography component="h1" variant="h4" sx={{marginBottom: 3}}>
        Confirmar Votantes
      </Typography>
      {
        !isEmpty(alertMessage) && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          <AlertTitle>{alertMessage}</AlertTitle>
        </Alert>
      }

     
      <Typography component="h3" variant="body2" sx={{marginBottom: 3}}>
        Pendientes de confirmación: {countLeft}
      </Typography>

      {isLoading && <CircularLoader /> }
      {!isLoading && countLeft > 0 ? renderNextVoter() : renderUpToDate()}

    </Card>


      
    </Container>
  </AdminLayout>
  );
};

export default ConfirmVoters;