import  useAuth  from '../../hooks/useAuth';
import {
  Routes,
  Route,
  Link,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { APPLICATION_SUPER_ADMIN } from '../../helpers/roles';
/*
export const ProtectedRoute = ({ children }) => {
  debugger;
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};*/

export const ProtectedRoute = ({ redirectPath = '/log-in' }) => {
  const { isLoggedIn, login, logout } = useAuth();
  if (!isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};


export const SuperAdminRoute = ({ redirectPath = '/log-in' }) => {
  const { isLoggedIn, getLoggedUserAttrSync } = useAuth();
  if (!isLoggedIn && getLoggedUserAttrSync("role_id") === APPLICATION_SUPER_ADMIN) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

