import { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const MAX_FILE_SIZE_MB = 5;
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/gif"];

const FileUploader = ({onFileSelected, useBase64}) => {
  const { t, i18n } = useTranslation(); 
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);


  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  const handleFileChange = async (event) => {
    let file = event.target.files[0];
    
    // File type validation
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setError("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
      return;
    }

    // File size validation
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setError(
        `File size exceeds ${MAX_FILE_SIZE_MB} MB. Please choose a smaller file.`
      );
      return;
    }
    if(useBase64){
      file['base64'] = await convertToBase64(file);
    }
    setSelectedFile(file);
    setError(null);
    if(onFileSelected !== null){
      onFileSelected(file);
    }
    
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      console.log("Uploading file...", formData);
    } else {
      console.error("No file selected");
    }
  };

  return (
    <Box p={3} border="1px dashed #ccc" borderRadius={3} textAlign="center">
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="image-file-input"
      />
      <label htmlFor="image-file-input">
        <Button variant="outlined" component="span">
         {t("common.uploader.upload")}
        </Button>
      </label>
      {selectedFile && (
        <div>
          <Typography variant="subtitle1" mt={2}>
            {t("common.uploader.selected")} {selectedFile.name}
          </Typography>
        </div>
      )}
      {error && (
        <Typography variant="body2" color="error" mt={2}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

FileUploader.propTypes = {
  onFileSelected:PropTypes.func.isRequired,
  useBase64: PropTypes.bool
};

FileUploader.defaultProps = {
    useBase64: true
};

export default FileUploader;