import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { LOGIN_ENDPOINT } from '../../routes';
import { postCall, putCall } from '../../helpers/apiManager';
import { AuthLayout  } from '../../layouts/auth/AuthLayout';
import { useForm, Controller } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import {  useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import { POLITICAL_PARTY_ENDPOINT } from '../../routes';
import { MuiColorInput } from 'mui-color-input'
import CommonTable from '../common/CommonTable';
import { green } from '@mui/material/colors';
import { useState } from 'react';
import { getCall } from '../../helpers/apiManager';
import { ThemeProvider, createTheme } from '@mui/material/styles';
//import { FormattedMessage } from '../common/i18n';
import { isEmpty } from 'lodash';
import FileUploader from '../common/FileUploader';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
  },
});

const CreatePoliticalParty = ({onSuccess, editData}) =>{
  const { t, i18n } = useTranslation(); 
  const [selectedFiles, setSelectedFiles] = useState([]);
  const {isLoggedIn, logout} = useAuth();

  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = React.useState('#ffffff');
  const [secondaryColor, setSecondaryColor] = React.useState('#ffffff')
  const [image, setImage] = React.useState(null);
  
  const [headers, setHeaders] = useState(["Acciones", "Party Name", "Representative", "Primary Color", "Secondary Color"]);
  const [rows, setRows] = useState([]);

  const handlePrimaryChange = (newValue) => {
    setPrimaryColor(newValue)
  }
  const handleSecondaryChange = (newValue) => {
    setSecondaryColor(newValue)
  }

  const form = useForm({
    defaultValues: {
      party_name: "",
      abbr_name: "",
      representative: "",
      primary_color: "",
      secondary_color: ""
    },
  })

  const { 
    handleSubmit, 
    register,
    formState,
    watch,
    setValue
  } = form;

  const {errors} = formState;

  useEffect( () => {
    const fetchParties = async () =>{
      const partiesR = await getCall(POLITICAL_PARTY_ENDPOINT);
      if(!partiesR.success && partiesR.login_redirect){
        logout();
      }
      const data = partiesR.data.map((value) => {
        return { 
          "Party Name" : value.party_name , 
          "Primary Color": value.primary_color, 
          "Secondary Color": value.secondary_color,
          "Representative": value.representative,
          "Acciones": <a href="#">Editar {value.id}</a>
        } 
      });
      setRows(data);
    }
    fetchParties();
  }, []);

  useEffect( () => {
   if(!isEmpty(editData)){
    for (let key in editData) {
      setValue(key, editData[key]);
    }
    setPrimaryColor(editData['primary_color'] || '#000');
    setSecondaryColor(editData['secondary_color'] || '#000');
   }
  }, [editData]);

  const onSubmit = async  (data) => { 
    data['primary_color'] =  primaryColor;
    data['secondary_color'] = secondaryColor;
    data['logo'] = image.base64;
    
    let results = null;
    let action = ''
    if(!isEmpty(editData)){
      results = await putCall(`${POLITICAL_PARTY_ENDPOINT}/${data.id}` , data);
      action = 'actualizado';
    }
    else{
      results = await postCall(POLITICAL_PARTY_ENDPOINT, data);
      action = 'creado';
    }
    
    if(!results.success){
      alert(results.message);
    }
    else{
      onSuccess(`El partido político ha sido ${action} exitosamente.`);
    }
    console.log(results);
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };


  return(
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
        <h1>{t("politicalParty.title")}</h1>
        <TextField
          margin="normal"
          required
          fullWidth
          id="abbr_name"
          label={t("politicalParty.abbrPartyName")}
          name="abbr_name"
          autoComplete="abbr_name"
          autoFocus
          {...register("abbr_name", { required: "Abbr is required." })}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="party_name"
          label={t("politicalParty.partyName")}
          type="party_name"
          id="party_name"
          autoComplete="party_name"
          {...register("party_name", { required: "Name is required." })}
          error={!!errors.party_name}
          helperText={errors.party_name?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="representative"
          label={t("politicalParty.representative")}
          type="representative"
          id="representative"
          autoComplete="representative"
          {...register("representative", { required: "Representative is required." })}
          error={!!errors.representative}
          helperText={errors.representative?.message}
        />
        <Box 
          flex={1} 
          display={'flex'} 
          justifyContent={'space-between'} 
          sx={{
            marginTop: 1,
          }}
        >
          <p>{t("politicalParty.primaryColor")}&nbsp;</p>
          <MuiColorInput 
            format="hex" 
            value={primaryColor} 
            onChange={handlePrimaryChange} 
          />
        </Box>
        <Box flex={1} 
          display={'flex'} 
          justifyContent={'space-between'} 
          sx={{
            marginTop: 1,
          }} >
          <p>{t("politicalParty.secondaryColor")}&nbsp;</p>
          <MuiColorInput 
            format="hex" 
            value={secondaryColor} 
            onChange={handleSecondaryChange} 
          />
        </Box>  
        <p>{t("politicalParty.flagImage")}&nbsp;</p>
        <Box 
          sx={{
            marginTop: 2,
          }} >
          <FileUploader 
            onFileSelected={(image) => {
              setImage(image);
            }}
          />
        </Box> 
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          color="primary"
        >
          {t("common.button.save")}
        </Button>
      </Box>
    </>
    

  )
 
};

CreatePoliticalParty.propTypes = {
  onSuccess:PropTypes.func.isRequired,
  editData: PropTypes.object
};

CreatePoliticalParty.defaultProps = {
};

export default CreatePoliticalParty;