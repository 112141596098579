import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { LOGIN_ENDPOINT } from '../../routes';
import { putCall } from '../../helpers/apiManager';
import { AuthLayout  } from '../../layouts/auth/AuthLayout';
import { useForm, Controller } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import {  useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import ThemeContext from '../../context/themeContext';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import CircularLoader from '../common/CircularLoader';
import { USERS_ENDPOINT } from '../../routes';
import { getCall } from '../../helpers/apiManager';

const Account = () =>{
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const theme = useContext(ThemeContext);


  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); 

  const form = useForm({
    defaultValues: {
      first_name: " ",
      last_name: " ",
      email: " ",
      phone: " ",
      password: " ",
      password_confirmation: " "
    },
  })

  const { 
    handleSubmit, 
    register,
    formState,
    watch,
    setValue,
    getValues
  } = form;

  const {errors} = formState;


  const fetchUsers = async () =>{
    const userR = await getCall(USERS_ENDPOINT+"/profile");
    if(userR.success){
      for (let key in userR.user) {
        setValue(key, userR.user[key], { shouldTouch: true });
        console.log("Setting: " + key + " => "+ userR.user[key]);
      }
    }
  }


  useEffect( () => {
    fetchUsers(); 
  }, []);

  const onSubmit = async  (data) => { 
    const results = await putCall(USERS_ENDPOINT, {"user": data});
    if(!results.success){
      setAlertMessage(t(results.message));
      setTimeout(() => {
        setAlertMessage("");
      }, 8000)

    }
    else{
     

    }
    console.log(results);
  }

  return(
    <AdminLayout>
      <Container component="main" >
        <CssBaseline />
        {
        !isEmpty(alertMessage) && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          <AlertTitle>{alertMessage}</AlertTitle>
        </Alert>
        }
        <Box
        sx={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >     
        <Typography component="h1" variant="h5">
          {t("account.title")}
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate >
          <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="first_name"
                    label={t('common.textfield.firstName')}
                    name="first_name"
                    autoComplete="first_name"
                    {...register("first_name", { required: "First name is required." })}
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message}
                />
              </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="last_name"
                label={t("common.textfield.lastName")}
                name="last_name"
                {...register("last_name", { required: "Last name is required." })}
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('common.textfield.email')}
                name="email"
                {...register("email", { required: "Please enter a valid email." })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label={t('common.textfield.phone')}
                name="phone"
                autoComplete="phone"
                autoFocus
                {...register("phone", { required: "Phone number is required" })}
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            </Grid>
          </Grid>
              {isLoading && <CircularLoader /> }
              {
                !isLoading && <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t('account.btnUpdate')}
                </Button>
              }
          </Box>
        </Box>
      </Container>
    </AdminLayout>
  )
};

export default Account;