import ChartBarIcon from '@heroicons/react/24/solid/ChartBarIcon';
import CogIcon from '@heroicons/react/24/solid/CogIcon';
import LockClosedIcon from '@heroicons/react/24/solid/LockClosedIcon';
import ShoppingBagIcon from '@heroicons/react/24/solid/ShoppingBagIcon';
import UserIcon from '@heroicons/react/24/solid/UserIcon';
import UserPlusIcon from '@heroicons/react/24/solid/UserPlusIcon';
import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import XCircleIcon from '@heroicons/react/24/solid/XCircleIcon';
import CheckBadgeIcon from '@heroicons/react/24/solid/CheckBadgeIcon';
import FlagIcon from '@heroicons/react/24/solid/FlagIcon';
import ChartPieIcon from '@heroicons/react/24/solid/ChartPieIcon';
import WrenchScrewdriverIcon from '@heroicons/react/24/solid/WrenchScrewdriverIcon';
import UserGroupIcon from '@heroicons/react/24/solid/UserGroupIcon';
import GlobeAmericasIcon from '@heroicons/react/24/solid/GlobeAmericasIcon';

import { SvgIcon } from '@mui/material';


/*
  1	Application Super Admin	-> Todas las paginas
  2	Product Admin	 -> Creacion de campañas
  3	Campaign Admin -> Crea usuarios
  4	Campaign User	 -> Agrega usuarios
*/

//const { t, i18n } = useTranslation(); 

export const commonShared =  [
  {
    title:  'sidebar.account',
    path: '/account',
    icon: (
      <SvgIcon fontSize="small">
        <UserIcon />
      </SvgIcon>
    )
  },
  /*{
    title:  'sidebar.settings',
    path: '/settings',
    icon: (
      <SvgIcon fontSize="small">
        <CogIcon />
      </SvgIcon>
    )
  }*/
];

export const campaignUserCommonItems = [
  ...[
    {
      title: 'sidebar.overview',
      path: '/',
      icon: (
        <SvgIcon fontSize="small">
          <ChartPieIcon />
        </SvgIcon>
      )
    },
    {
      title: 'sidebar.manageVoters',
      path: '/voters',
      icon: (
        <SvgIcon fontSize="small">
          <ChartBarIcon />
        </SvgIcon>
      )
    }
  ],
];


export const campaignUserItems = [
  ...campaignUserCommonItems,
  ...commonShared
]

export const callCenterItems = [
  ...[{
    title: 'sidebar.callCenter',
    path: '/confirm-voters',
    icon: (
      <SvgIcon fontSize="small">
        <CheckBadgeIcon />
      </SvgIcon>
    )
  }],
  ...commonShared
]


export const campaignAdminItems = [
  ...campaignUserCommonItems,
  ...[
    {
      title: 'sidebar.manageUsers',
      path: '/auth/register',
      icon: (
        <SvgIcon fontSize="small">
          <UserPlusIcon />
        </SvgIcon>
      ),
    },
  ],
  ...commonShared
]

export const productAdminItems = [
  {
    title: 'sidebar.managePoliticalParty',
    path: '/admin/political-parties',
    icon: (
      <SvgIcon fontSize="small">
        <FlagIcon />
      </SvgIcon>
    )
  },
  {
    title: 'sidebar.manageCampaign',
    path: '/admin/campaigns',
    icon: (
      <SvgIcon fontSize="small">
        <GlobeAmericasIcon />
      </SvgIcon>
    ),
  },
  {
    title: 'sidebar.manageClients',
    path: '/auth/register',
    icon: (
      <SvgIcon fontSize="small">
        <UserGroupIcon />
      </SvgIcon>
    )
  },
]



export const applicationSuperAdminItems = [
  ...[{
    title: 'sidebar.superAdmin',
    path: '/super-admin-tools',
    icon: (
      <SvgIcon fontSize="small">
        <WrenchScrewdriverIcon />
      </SvgIcon>
    )
  },
  {
    title: 'sidebar.dashboard',
    path: '/',
    icon: (
      <SvgIcon fontSize="small">
        <ChartPieIcon />
      </SvgIcon>
    )
  }],
  ...productAdminItems,
  
]




export const common = [
  {
    title: 'sidebar.dashboard',
    path: '/',
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    )
  },
  {
    title: 'sidebar.dataEntry',
    path: '/admin/data-entry',
    icon: (
      <SvgIcon fontSize="small">
        <UsersIcon />
      </SvgIcon>
    )
  },
  {
    title: 'sidebar.registerUser',
    path: '/auth/register',
    icon: (
      <SvgIcon fontSize="small">
        <UserPlusIcon />
      </SvgIcon>
    ),
  },
  {
    title: 'Example 3',
    path: '/auth/register',
    icon: (
      <SvgIcon fontSize="small">
        <UserPlusIcon />
      </SvgIcon>
    ),
  },
  {
    title: 'Companies',
    path: '/companies',
    icon: (
      <SvgIcon fontSize="small">
        <ShoppingBagIcon />
      </SvgIcon>
    )
  },
  {
    title: 'Account',
    path: '/account',
    icon: (
      <SvgIcon fontSize="small">
        <UserIcon />
      </SvgIcon>
    )
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: (
      <SvgIcon fontSize="small">
        <CogIcon />
      </SvgIcon>
    )
  },
  {
    title: 'Login',
    path: '/auth/login',
    icon: (
      <SvgIcon fontSize="small">
        <LockClosedIcon />
      </SvgIcon>
    )
  },
 
  {
    title: 'Error',
    path: '/404',
    icon: (
      <SvgIcon fontSize="small">
        <XCircleIcon />
      </SvgIcon>
    )
  }
];
