import './App.css';
import {Routes, Route, Link} from 'react-router-dom';
import Login from './components/Login/';
import SignUp from './components/SignUp/';
import Dashboard from './components/Dashboard/';

import Footer from './components/common/Footer';


import {  StyledEngineProvider } from '@mui/material';
import { ThemeProvider/*, createTheme*/ } from '@mui/material/styles';

import SiteRoutes from './components/SiteRoutes/SiteRoutes';
import { I18nextProvider } from 'react-i18next';
import i18next from './config/i18n';
import { green, purple, red , yellow} from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import { Button } from '@mui/material';
import { createTheme as createMuiTheme } from '@mui/material';
import { createTheme } from './theme';
import _ from "lodash";
import ThemeContext from './context/themeContext';
import { useState } from 'react';
import useAuth from './hooks/useAuth';
import { useEffect } from 'react';
import './assets/fonts/fonts.css';

function App() {

  const {getLoggedUserAttr, isLoggedIn} = useAuth();


  const [colors, setColors] = useState(createMuiTheme({
    palette: {
      primary: {
        main: "#8566EC",
      },
      secondary: {
        main: "#000",
      },
    },
  }));

  useEffect( () => {

    const getThemeData = async () => {
      let colorParty = ['#8566EC', '#000'];
      
      if(isLoggedIn){
        const colors  = await getLoggedUserAttr('colors');
        if(colors !== undefined){
          colorParty = colors;
        }
      }
     
      setColors(createMuiTheme({
        palette: {
          primary: {
            main: colorParty[0],
          },
          secondary: {
            main: colorParty[1],
          },
        },
      }))
    }

    getThemeData();

  }, []);






  return (
    <>
      <ThemeContext.Provider value={{colors, setColors}}>
        <I18nextProvider i18n={i18next}>
          <ThemeProvider theme={_.merge( createTheme(),colors )}>
              <StyledEngineProvider injectFirst>
                <CssBaseline />
                <SiteRoutes />
                <Footer />
              </StyledEngineProvider>
            </ThemeProvider>
        </I18nextProvider>
      </ThemeContext.Provider>
    </>
  );
}



export default App;
