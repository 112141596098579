import { Link } from 'react-router-dom';
// material-ui
import { Grid, Stack, Typography } from '@mui/material';
import { Layout } from '../../layouts/admin/layout';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
// third-party
import ReactApexChart from 'react-apexcharts';
import { DASHBOARDS_ENDPOINT } from '../../routes';
import { getCall } from '../../helpers/apiManager';
const areaChartOptions = {
  chart: {
    height: 350,
    type: 'area'
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  xaxis: {
    type: 'datetime',
    categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
  },
  tooltip: {
    x: {
      format: 'dd/MM/yy HH:mm'
    },
  },
};


const CallCenterDashboard = () => {
    
  const theme = useTheme();

  
  const slot = 'Jan';

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(areaChartOptions);

  const areaChartValues = {
    
  }


  const barChartValues = { options: {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: []
    }
  },
  series: [
    {
      name: "series-1",
      data: []
    }
  ]};


  const [voterPerWeek, setVoterPerWeek] = useState(barChartValues);
  const [voterPerMonth, setVoterPerMonth] = useState(barChartValues);
  const [sumVoterPerWeek, setSumVoterPerWeek] = useState(barChartValues);
  const [sumVoterPerMonth, setSumVoterPerMonth] = useState(barChartValues);
  const [voterPerProvince, setVoterPerProvince] = useState({});
  const [voterPerGender, setVoterPerGender] = useState({});
  
  const [voterPerCanton, setVoterPerCanton] = useState({});

  const getBasicBarDataObject  = (categories, data, id, title, subtitle) => {
    const optionsBasicBar = {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: categories
      },
      title: {
        text: title,
        align: 'left'
      },
      subtitle: {
        text: subtitle,
        align: 'left'
      },
      
    };

    const seriesBasicBar = [
      {
        name: id,
        data: data
      }
    ];
    return {"options": optionsBasicBar, "series" : seriesBasicBar};
  }

  const fetchKeysAndValues = (objectTarget) => {
    const keys = [];
    const values = [];
    for (let key in objectTarget) {
      keys.push(key);
      values.push(objectTarget[key]);
    }
    return {"keys": keys, "values": values};
  }

  useEffect(() => {

    const fetchData = async () => {
      const dashboardsResults = await getCall(DASHBOARDS_ENDPOINT);
      //setProvinces(prepareDropdownData(provincesResults.provinces));
      if(dashboardsResults.success){
        const dashboards = dashboardsResults.dashboards;
        console.log(dashboards);

        if(dashboards == null){
          return;
        }

        const weekArray = fetchKeysAndValues(dashboards.voters_week);
        const monthArray = fetchKeysAndValues(dashboards.voters_month);
        const provinceArray = fetchKeysAndValues(dashboards.voters_per_province);
        const genderArray = fetchKeysAndValues(dashboards.numbers_per_gender);

        let totalWeek = 0;
        let sumWeekArrayValues = weekArray.values.map((element) => {          
          totalWeek += element;
          return totalWeek;
        });
        


        const weeklyData = getBasicBarDataObject(weekArray.keys, weekArray.values, "votersWeekly", "Cantidad de votos confirmados", "Datos Divididos Por Semana");
        const monthyData = getBasicBarDataObject(monthArray.keys, monthArray.values, "votersMonthly",  "Cantidad de votos confirmados", "Datos Divididos Por Mes");

        setVoterPerWeek({"raw" : dashboards.voters_week, "series": weeklyData.series, "options" : weeklyData.options});
        setVoterPerMonth({"raw" : dashboards.monthyData, "series": monthyData.series, "options" : monthyData.options});
        setVoterPerProvince({"raw" : dashboards.voter_per_province, "series": provinceArray.values, "options" : {
          "labels":provinceArray.keys,
          title: {
            text: "Cantidad intención de voto",
            align: 'left'
          },
          subtitle: {
            text: "Datos divididos por provincia",
            align: 'left'
          },
        
        }} );

        setVoterPerGender({
          "raw" : dashboards.numbers_per_gender, "series": genderArray.values, "options" : {
          "labels":genderArray.keys,
          title: {
            text: "Cantidad intención de voto",
            align: 'left'
          },
          subtitle: {
            text: "Datos divididos por género",
            align: 'left'
          },
        
        }})

        setSeries(
          [{
            name: 'Votos',
            data: sumWeekArrayValues
          }]
        );

        setOptions({...weeklyData.options, ...{
          stroke: 
          { 
            curve: 'smooth', 
            chart: {
              height: 350,
              type: 'area'
            },
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },
          },
          title: {
            text: 'Histórico de intención de voto',
            align: 'left'
          },
          subtitle: {
            text: 'Sumatoria de Datos Por Semana',
            align: 'left'
          },
        }
        });
        setVoterPerCanton(dashboards.voter_per_canton);
      }
    }

    fetchData();

  }, []);

  const [series, setSeries] = useState([
    {
      name: 'Votos',
      data: []
    },
   /* {
      name: 'Tiempo',
      data: [0, 43, 14, 56, 24, 105, 68]
    }*/
  ]);

  useEffect(() => {
    setSeries([
      {
        name: 'Votos',
        data: []
      }
     /* {
        name: 'Votos',
        data: slot === 'month' ? [76, 85, 101, 98, 87, 105, 91, 114, 94, 86, 115, 35] : [31, 40, 28, 51, 42, 109, 100]
      },*/
      /*{
        name: 'Tiempo',
        data: slot === 'month' ? [110, 60, 150, 35, 60, 36, 26, 45, 65, 52, 53, 41] : [11, 32, 45, 32, 34, 52, 41]
      }*/
    ]);
  }, [slot]);

    return(
      <Grid container spacing={2}>
        <h1>Call center Dashboard coming soon!</h1>
      </Grid> 
    )  
};
  
  export default CallCenterDashboard;