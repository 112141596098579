import { CircularProgress, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const CircularLoader = ({ size }) => (
  <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress size={size} color="primary" />
  </Grid>
);

CircularLoader.propTypes = {
  size: PropTypes.number,
};

CircularLoader.defaultProps = {
  size: 60,
};

export default CircularLoader;
