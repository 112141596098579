import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ChipDropdown from '../common/ChipDropdown';
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import { useForm, Controller } from "react-hook-form"
import { postCall } from '../../helpers/apiManager';
import { USERS_ENDPOINT } from '../../routes';
import Registration from './Registration';
import ManageRegistration from './ManageRegistration';
const AdminRegistration = () =>{

  const form = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      country: "Costa Rica",
      level: 1
    },
  })

  const { 
    handleSubmit, 
    register,
    formState,
    watch
  } = form;

  const {errors} = formState;

  const onSubmit = async  (data) => {
    const consolidatedData = {user: {...data, ...{full_name: `${data.first_name} ${data.last_name}`}}};    
    const results = await postCall(USERS_ENDPOINT, consolidatedData, false);
    if(!results.success){
      let errors = "";
      for(let i = 0 ; i < results.errors.length; i++){
        errors+= results.errors[i] + "\n";
      }
      alert(errors);
    }
    else{
      alert("User created successfully, please check your email to activate it.");
    }
    console.log(results);
  }

  return(
    <AdminLayout>
      <Container component="main" >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >          
          <ManageRegistration />
        </Box>
      </Container>
    </AdminLayout>
    )
 
  };

export default AdminRegistration;