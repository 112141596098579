import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { LOGIN_ENDPOINT } from '../../routes';
import { postCall } from '../../helpers/apiManager';
import { AuthLayout  } from '../../layouts/auth/AuthLayout';
import { useContext } from 'react';
import ThemeContext from '../../context/themeContext';
import { useTranslation } from 'react-i18next';

function Copyright(props) {
  const { t } = useTranslation(); 

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {t('copyright')}
      <Link color="inherit" href="#">
      On Latam
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const NotFound = () =>{
  const theme = useContext(ThemeContext);
  const { t, i18n } = useTranslation(); 
  

  return(
    <AuthLayout>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            Page Not Found
        </Box>
      </Container>
    </AuthLayout>
    )
  };

export default NotFound;