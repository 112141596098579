import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import {  useEffect } from 'react';
export default function BasicDatePicker({label, onChange, elementId, defaultValue}) {
  const [value, setValue] = React.useState(dayjs());

  useEffect(() => {
    
    if(!isEmpty(defaultValue)){
      setValue(dayjs(defaultValue));
    }
   }, [defaultValue]);


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']} sx={{ marginTop: 2 }}>
        <DatePicker 
          label={label} 
          value={value}
          onChange={
            (newValue) => {
              setValue(newValue);
              if(onChange != null){
                onChange(elementId, newValue);
              }
            }
          }  
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

BasicDatePicker.propTypes = {
  label: PropTypes.string,
  elementId: PropTypes.string,
  onChange:PropTypes.func,
  defaultValue: PropTypes.string,
};

BasicDatePicker.defaultProps = {
    label: 'Fecha',
    onChange: null,
    elementId: '',
    defaultValue: null
};