import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const statusMap = {
  pending: 'warning',
  willVote: 'success',
  declined: 'error'
};


const CommonTable = ({headers, rows, name, useI18n}) =>{
  const { t, i18n } = useTranslation(); 


  const renderHeaders = () => {
    const headerData = headers.map((header) => (
      <TableCell>
        {useI18n ? t(header) : header}
      </TableCell>
    ));
    return (
      <TableHead key={`tablehead-${name}`}>
        <TableRow key={`tablerow-${name}`}>
          {headerData}
        </TableRow>
      </TableHead>
    );
  };


  const renderRow = (row, rowIndex) =>{
    return row.map ((field, index) => (
      <TableCell key={`field-${rowIndex}-${index}`}>
        {field}
      </TableCell>
    ));
  }

  const convertRowObjectToArray = (row) =>{
    let rowData = [];
    for (const header of headers) {
      rowData.push(row[header]);
    }
    return rowData;
  }

  const renderRows = () => {
    const rowsData = rows.map((row, index) => {
      row =  Array.isArray(row) ? row : convertRowObjectToArray(row);
      //console.log(`Key: table-${index}`);
      return (
        <TableRow
          hover
          key={`table-${index}`}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          {renderRow(row,index)}
        </TableRow>
      )
  });

    return (
      <TableBody>
        {rowsData}
      </TableBody>
    );
  };


  return(
    <Table>
      {headers && renderHeaders()}
      {renderRows()}
    </Table>
  );

}


CommonTable.propTypes = {  
  children: PropTypes.node,
  headers: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.array,
  name: PropTypes.string.isRequired,
  useI18n: PropTypes.bool
};


CommonTable.defaultProps = {
  useI18n: true,
  headers: ["Cédula", "Nombre", "Primer Apellido", "Segundo Apellido" ],
  rows: [{"Cédula": "123", "Nombre" : "Jose", "Primer Apellido" : "Prueba", "Segundo Apellido": "Segundo apellido"},
  {"Cédula": "123", "Nombre" : "Jose", "Primer Apellido" : "Prueba", "Segundo Apellido": "Segundo apellido"},
  {"Cédula": "123", "Nombre" : "Jose", "Primer Apellido" : "Prueba", "Segundo Apellido": "Segundo apellido"},
  {"Cédula": "123", "Nombre" : "Jose", "Primer Apellido" : "Prueba", "Segundo Apellido": "Segundo apellido"}
],
  //rows: [["Cédula", "Nombre", "Primer Apellido", "Segundo Apellido" ]],
};

export default CommonTable;