import { CircularProgress, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
const style = {
    image:{
        filter: 'grayscale(100%)',
        position: 'absolute', 
        fontSize: 40, 
        marginTop: '-120px', 
        marginLeft: '45px', 
        color: '#fff',
        WebkitFilter: 'grayscale(100%)'
    },
  };


const Level = ({ size, level }) => {
  const { t } = useTranslation(); 
  return(
    <Grid item >
      <img 
          height={size} 
          width={size}
          src={require(`./images/frame_${level}.png`)} />
      <h1 
        style={
        {
          ...style.image, 
          ...{
            marginTop: `-${size-6}px`, 
            marginLeft: `${5}px`,
            position:'absolute',
            width: '90px',
            textAlign: 'center'
          }
        }
      }>
        {level}
      </h1>
      <h4 style={{textAlign:'center', width: size}} >{t("dashboard.campaignUser.level")} {level}</h4>
    </Grid>
  )
};

Level.propTypes = {
  size: PropTypes.number,
  level: PropTypes.number,
};

Level.defaultProps = {
  size: 100,
  level: 1
};

export default Level;
