import { Link } from 'react-router-dom';
// material-ui
import { Grid, Stack, Typography, Paper } from '@mui/material';
import { Layout } from '../../layouts/admin/layout';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
// third-party
import ReactApexChart from 'react-apexcharts';
import { DASHBOARDS_ENDPOINT } from '../../routes';
import { getCall } from '../../helpers/apiManager';
import CommonTable from '../common/CommonTable';
import { isEmpty } from 'lodash';

const areaChartOptions = {
  chart: {
    height: 350,
    type: 'area'
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  xaxis: {
    type: 'datetime',
    categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
  },
  tooltip: {
    x: {
      format: 'dd/MM/yy HH:mm'
    },
  },
};


const AdminDashboard = () => {
    
  const theme = useTheme();
  const slot = 'Jan';
  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;
  const headers = ["points", "potential_voters_added",  "first_name", "last_name", "email", 'phone'];
  const [leaderboards, setLeaderboards] = useState([]);
  const [options, setOptions] = useState(areaChartOptions);

  const barChartValues = { options: {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: []
    }
  },
  series: [
    {
      name: "series-1",
      data: []
    }
  ]};


  const [voterPerWeek, setVoterPerWeek] = useState(barChartValues);
  const [voterPerMonth, setVoterPerMonth] = useState(barChartValues);

  const [voterPerProvince, setVoterPerProvince] = useState({});
  const [voterPerGender, setVoterPerGender] = useState({});
  const [voterStatus, setVoterStatus] = useState({});
  const [weeklyVoterPerStatus, setWeeklyVoterPerStatus] = useState(barChartValues);
  
  
  const [voterPerCanton, setVoterPerCanton] = useState({});
  
  const [showData, setShowData] = useState(true);
  const [cantonGraph, setCantonGraph] = useState({
    series: [
    ],
    options: {
      legend: {
        show: true
      },
      chart: {
        height: 450,
        type: 'treemap'
      },
      title: {
        text: 'Distribución por Cantones',
        align: 'center'
      }
    }
  })

  const getBasicBarDataObject  = (categories, data, id, title, subtitle) => {
    const optionsBasicBar = {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: categories
      },
      title: {
        text: title,
        align: 'left'
      },
      subtitle: {
        text: subtitle,
        align: 'left'
      },
      colors: [ theme.palette.primary.main],
    };

    const seriesBasicBar = [
      {
        name: id,
        data: data
      }
    ];
    return {"options": optionsBasicBar, "series" : seriesBasicBar};
  }

  const fetchKeysAndValues = (objectTarget) => {
    const keys = [];
    const values = [];
    for (let key in objectTarget) {
      keys.push(key);
      values.push(objectTarget[key]);
    }
    return {"keys": keys, "values": values};
  }

  useEffect(() => {
    const fetchData = async () => {
      const dashboardsResults = await getCall(DASHBOARDS_ENDPOINT);
      //setProvinces(prepareDropdownData(provincesResults.provinces));
      if(dashboardsResults.success){
        const dashboards = dashboardsResults.dashboards;
        console.log(dashboards);
        const weekArray = fetchKeysAndValues(dashboards.voters_week);
        const monthArray = fetchKeysAndValues(dashboards.voters_month);
        const provinceArray = fetchKeysAndValues(dashboards.voters_per_province);
        const genderArray = fetchKeysAndValues(dashboards.numbers_per_gender);
        const voterStatusArray = fetchKeysAndValues(dashboards.voter_status);

        const weeklyVoterPerStatusArray = fetchKeysAndValues(dashboards.voter_status_week);
        

        let statuses = [''];
        let dates = Object.keys(dashboards.voter_status_week);
        const entries = Object.entries(dashboards.voter_status_week);
        let series = {
          "Confirmado": [], 
          "Incluido": [],
          "Negado": [],
          "Pendiente": []
        };
        let values = [];
       // const v = Object.values(dashboards.voter_status_week);
        
       
        entries.forEach((el) => {
          Object.entries(el[1]).forEach((status) => {
            series[status[0]].push(status[1]);
          });
        });

        setWeeklyVoterPerStatus( 
          {
            series: [
              {
                name: "Confirmado",
                data: series["Confirmado"]
              }, 
              {
                name: "Incluido",
                data: series["Incluido"]
              },
              {
                name: "Negado",
                data: series["Negado"]
              },
              {
                name: "Pendiente",
                data: series["Pendiente"]
              }
            ],
            chart: {
            type: 'bar',
            height: 430
          },
          options:{
            xaxis: {
              categories: ['Confirmado', 'Incluido', 'Negado', 'Pendiente']
            },
            title: {
              text: 'Visual Semanal General',
              align: 'left'
            },
            subtitle: {
              text: 'Datos divididos por votantes contactados',
              align: 'left'
            },
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  position: 'top',
                },
              }
            },
            dataLabels: {
              enabled: true,
              offsetX: -6,
              style: {
                fontSize: '12px',
                colors: ['#fff']
              }
            },
            stroke: {
              show: true,
              width: 1,
              colors: ['#fff']
            },
            tooltip: {
              shared: true,
              intersect: false
            },
            xaxis: {
              categories: dates,
            },
          }
          
        })

        setShowData(!isEmpty(voterStatusArray.values))
        
        setLeaderboards(dashboards.leaderboards);
        let totalWeek = 0;
        let sumWeekArrayValues = weekArray.values.map((element) => {          
          totalWeek += element;
          return totalWeek;
        });
        const weeklyData = getBasicBarDataObject(weekArray.keys, weekArray.values, "votersWeekly", "Cantidad de votos confirmados", "Datos Divididos Por Semana");
        const monthyData = getBasicBarDataObject(monthArray.keys, monthArray.values, "votersMonthly",  "Cantidad de votos confirmados", "Datos Divididos Por Mes");
        setVoterPerWeek({"raw" : dashboards.voters_week, "series": weeklyData.series, "options" : weeklyData.options});
        setVoterPerMonth({"raw" : dashboards.monthyData, "series": monthyData.series, "options" : monthyData.options});
        setVoterPerProvince({"raw" : dashboards.voter_per_province, "series": provinceArray.values, "options" : {
          "labels":provinceArray.keys,
          title: {
            text: "Intención de voto confirmado por provincia",
            align: 'left'
          },
          subtitle: {
            text: "Datos divididos por provincia",
            align: 'left'
          },
        }});


        
        setVoterPerGender({
          "raw" : dashboards.numbers_per_gender, "series": genderArray.values, "options" : {
            "labels":genderArray.keys,
            title: {
              text: "Cantidad intención de voto por género",
              align: 'left'
            },
            subtitle: {
              text: "Datos divididos por género",
              align: 'left'
            },
        }});

        setVoterStatus({
          "raw" : dashboards.voter_status, "series": voterStatusArray.values, "options" : {
            "labels":voterStatusArray.keys,
            title: {
              text: "Progreso de votantes contactados",
              align: 'left'
            },
            subtitle: {
              text: "Datos divididos por Estado",
              align: 'left'
            },
        }});

        setSeries(
          [{
            name: 'Votos',
            data: sumWeekArrayValues
          }]
        );

        setOptions({...weeklyData.options, ...{
          stroke: 
          { 
            curve: 'smooth', 
            chart: {
              height: 350,
              type: 'area'
            },
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },
          },
          title: {
            text: 'Histórico de intención de voto',
            align: 'left'
          },
          subtitle: {
            text: 'Sumatoria de Datos Por Semana',
            align: 'left'
          },
        }
        });

        const cantonData = []
        for (let [provinceName, cantons] of Object.entries(dashboards.voters_per_canton)) {
          let data = [];
          for (let [cantonKey, canton] of Object.entries(cantons)) {
            data.push({'x': `${canton.canton}`, 'y': canton.value});
          }
          cantonData.push({name: provinceName, data: data});
        }
        const newCantonGraph = {...cantonGraph, ...{'series': cantonData}};
        setCantonGraph(newCantonGraph);
        setVoterPerCanton(dashboards.voter_per_canton);
      }
    }
    fetchData();
  }, []);

  const [series, setSeries] = useState([
    {
      name: 'Votos',
      data: []
    },
  ]);

  useEffect(() => {
    setSeries([
      {
        name: 'Votos',
        data: []
      }
    ]);
  }, [slot]);


  const renderEmptyPage = () => (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: 'center', padding: 30 }}>
          <Paper elevation={1} style={{ textAlign: 'center', padding: 100 }}>
            <h3>Tan pronto existan datos de la campaña podrá visualizarlos aquí</h3>
          </Paper>
        </Grid>
      </Grid>
     
    </>
  )

  const renderDashboardsPage = () => {
    return (
      <Grid container spacing={2}>


       

        <Grid item xs={12} md={12}>
          <ReactApexChart 
          options={
          weeklyVoterPerStatus.options
          } 
          series={
            weeklyVoterPerStatus.series
          } type="bar" height={430} />
        </Grid>

        <Grid item xs={12} md={12}>
          <Paper elevation={1}>
            <ReactApexChart options={options} series={series} type="area" />
          </Paper>
        </Grid>
       
        
        <Grid item xs={12} md={6}>
          <Paper elevation={1}>
            <ReactApexChart options={voterPerMonth.options} series={voterPerMonth.series} type="bar" height={450} />
          </Paper>
        </Grid> 
        <Grid item xs={12} md={6}>
          <Paper elevation={1}>
            <ReactApexChart options={voterPerWeek.options} series={voterPerWeek.series} type="bar" height={450} />
          </Paper>
        </Grid>  

        <Grid item xs={12} md={6}>
          <Paper elevation={1}>
            <ReactApexChart  options={voterStatus.options || {}} series={voterStatus.series || []} type="donut" height={450} />
          </Paper>
        </Grid>   

        
        <Grid item xs={12} md={6}>
          <Paper elevation={1}>
            <ReactApexChart options={voterPerProvince.options || {}} series={voterPerProvince.series || []} type="donut" height={450} />
          </Paper>
        </Grid>   
        <Grid item xs={12} md={6}>
          <Paper elevation={1}>
            <ReactApexChart options={voterPerGender.options || {}} series={voterPerGender.series || []} type="donut" height={450} />
          </Paper>
        </Grid>
        <Grid item xs={12} >
          <Paper elevation={1}>
            <ReactApexChart 
              options={cantonGraph.options} 
              series={cantonGraph.series} 
              type="treemap" 
              height={900} 
            />
          </Paper>
        </Grid>
        <Grid item xs={12} >
          <Paper elevation={1}>
            <CommonTable 
              headers={headers} 
              rows={leaderboards} 
              name="Leaderboards"
            />
          </Paper>
        </Grid> 
      </Grid> 
    )
  }

  return(
    <>     
      {showData ? renderDashboardsPage() : renderEmptyPage()}
    </>
  )
    
};
  
  export default AdminDashboard;