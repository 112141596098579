import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { LOGIN_ENDPOINT } from '../../routes';
import { postCall } from '../../helpers/apiManager';
import { AuthLayout  } from '../../layouts/auth/AuthLayout';
import { useForm, Controller } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import {  useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import { POLITICAL_PARTY_ENDPOINT } from '../../routes';
import { MuiColorInput } from 'mui-color-input'
import CommonTable from '../common/CommonTable';
import { green } from '@mui/material/colors';
import { useState } from 'react';
import { getCall } from '../../helpers/apiManager';
import { ThemeProvider, createTheme } from '@mui/material/styles';
//import { FormattedMessage } from '../common/i18n';
import ModalPopover from '../common/ModalPopover';
import CreatePoliticalParty from './CreatePoliticalParty';
import Edit from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const PoliticalParty = () =>{
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { t, i18n } = useTranslation(); 
  const {isLoggedIn, logout} = useAuth();

  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = React.useState('#ffffff');
  const [secondaryColor, setSecondaryColor] = React.useState('#ffffff')
  
  
  const [headers, setHeaders] = useState(["commonTable.header.actions", "commonTable.header.party_name", "commonTable.header.representative"]);
  const [rows, setRows] = useState([]);
  const [editData, setEditData] = useState({});
  const [alertMessage, setAlertMessage] = useState("");

  

  const handlePrimaryChange = (newValue) => {
    setPrimaryColor(newValue)
  }
  const handleSecondaryChange = (newValue) => {
    setSecondaryColor(newValue)
  }


  const form = useForm({
    defaultValues: {
      party_name: "",
      name: "",
      representative: "",
      primary_color: "",
      secondary_color: ""
    },
  })

  const { 
    handleSubmit, 
    register,
    formState,
    watch
  } = form;

  const prepareEditData = async (value) => {
    await setEditData(value);
    await setIsPopupOpen(true);
  }

  const fetchParties = async () =>{
    const partiesR = await getCall(POLITICAL_PARTY_ENDPOINT);
    if(!partiesR.success && partiesR.login_redirect){
      logout();
    }
    const data = partiesR.data.map((value) => {
      return { 
        "commonTable.header.party_name" : value.party_name , 
        "Primary Color": value.primary_color, 
        "Secondary Color": value.secondary_color,
        "commonTable.header.representative": value.representative,
        "commonTable.header.actions": (
          <IconButton  
            sx={{border: '1px solid'}} 
            aria-label="edit" 
            color="primary"
            onClick={() => prepareEditData(value)}
          >
            <Edit />
          </IconButton>)
          //<a href="#" onClick={ () => prepareEditData(value)}>Editar</a>
      } 
    });
    setRows(data);
  }

  useEffect( () => {
    fetchParties(); 
  }, []);

  return(
   
    <AdminLayout>
      <Container component="main">
        <CssBaseline />
        {
        !isEmpty(alertMessage) && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          <AlertTitle>{alertMessage}</AlertTitle>
        </Alert>
        }
        <Grid item xs={12} container alignItems="flex-end" justifyContent="flex-end">
          <Button    
            variant="contained"
            sx={{ mt: 3, mb: 2, alignSelf:'flex-end', justifyContent:'flex-end', alignContent:'flex-end' }}
            color="primary"
            size="large"
            onClick={()=> setIsPopupOpen(true)}
          >
            Agregar
          </Button>
        </Grid>
        <CommonTable 
          headers={headers} 
          rows={rows} 
        />
        <ModalPopover isOpen={isPopupOpen} onCloseFunction={() => setIsPopupOpen(false)} >
          <CreatePoliticalParty 
            onSuccess={(text)=> {
              setAlertMessage(text);
              fetchParties(); 
              setIsPopupOpen(false); 
              setTimeout(() => {
                setAlertMessage("");
              }, 20000)
            }}
            editData={editData}
          />
        </ModalPopover>
      </Container>
    </AdminLayout>
    

  )
 
};

export default PoliticalParty;