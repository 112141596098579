import { Grid, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
// third-party
import ReactApexChart from 'react-apexcharts';
import { DASHBOARDS_ENDPOINT } from '../../routes';
import { getCall } from '../../helpers/apiManager';
import Paper from '@mui/material/Paper';
import Level from '../Level/Level';
import Leaderboard from '../Leaderboard/Leaderboard';
import { useTranslation } from 'react-i18next';
//import useWindowSize from 'react-use/lib/useWindowSize'

import ConfettiExplosion from 'react-confetti-explosion';

const CampaignUserDashboard = () => {
  const { t } = useTranslation(); 
  const theme = useTheme();
  const { primary, secondary } = theme.palette;
  console.log(theme.palette.primary.main);
  const [votersAdded, setVotersAdded] = useState(0);

  const [votersConfirmed, setVotersConfirmed] = useState(0);
  const [points, setPoints] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [leaderboards, setLeaderboards] = useState([]);

  const [levelGraph, setLevelGraph] = useState({
    series: [90],
    options: {
      title: {
        text: "Progreso del nivel actual",
        align: 'left',
        style: {
          fontSize: '28px'
        }
      },
      chart: {
        height: 450,
        type: 'radialBar',
      },
      
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 270,
          hollow: {
            margin: 0,
            size: '65%',
            background: "#293450",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -10,
              show: true,
              //color: primary.main,
              color: "#fff",
              fontSize: "48px"
            },
            value: {
              //color: "#111",
              color: "#fff",
              fontSize: "34px",
              show: true,
              offsetY: +30,
            }
          },
          track: {
            background: '#fff',
            strokeWidth: '100%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          shadeIntensity: 0.4,
          gradientToColors: [secondary.main],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0,100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      colors: [primary.main, secondary.main],
      labels: ['Nivel 1'],
    },

  });

  const setDonutChartOptions = (data) =>{
    var options = {...levelGraph, ...{series: [data.percentage], options: {labels: [data.name]} }};
    setLevelGraph(options);
  }

  const getLevelData = (levels, currentPoints) => {
    let currentLevel = null;

    for (let i = 0 ; i < levels.length; i++){
      if(currentPoints < levels[i].next_level_at){
        // Found current Level
        currentLevel = {
          percentage: parseInt( (currentPoints - levels[i].starts_at) / (levels[i].next_level_at) * 100, 10) ,
          name: levels[i].name,
          id:  levels[i].id,
          nextLevel: (levels[i].next_level_at - currentPoints)
        }
        break;
      }
    } 

    if (currentLevel == null){
      const last = levels.length -1;
      currentLevel = {
        percentage: 100 ,
        name: 'MAX!',
        id:  levels[last].id,
        nextLevel: -1
      }
    }

    return currentLevel;

  }

  useEffect(() => {

    const fetchData = async () => {
      const dashboardsResults = await getCall(`${DASHBOARDS_ENDPOINT}`);
      console.log(dashboardsResults);
      //setProvinces(prepareDropdownData(provincesResults.provinces));
      if(dashboardsResults.success){
        const dashboards = dashboardsResults.dashboards;
        console.log(dashboards);
        setVotersAdded(dashboards.potential_voters_added);
        setVotersConfirmed(dashboards.potential_voters_confirmed);
        setPoints(dashboards.points);
        const levelData = getLevelData(dashboards.levels, dashboards.points);

        setDonutChartOptions(levelData);
        setCurrentLevel(levelData);
        setLeaderboards(dashboards.leaderboards);
      }
    }

    fetchData();

  }, []);


  const renderBadges = () => {
    let badges = [];
    if (currentLevel == null){
      return null;
    }
    else{
      for(let i = 1; i <= currentLevel.id ; i++){
        badges.push(<Level level={i} />);
      }
    }
    console.log(badges);
   return badges;
  }


  return(    
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <h1>{t("dashboard.campaignUser.title")}</h1>
      </Grid>

      <Grid item 
        xs={12} 
      >
        <Paper elevation={1}  sx={{display: 'flex', padding: '20px'}}>
          {renderBadges()}
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={1}>
          <ReactApexChart 
            options={levelGraph.options} 
            series={levelGraph.series} 
            type="radialBar" 
          />
        </Paper>
      </Grid>
      <ConfettiExplosion particleCount={300} width={800} force={0.4}  duration={4000} colors={[primary.main, secondary.main]} />
      <Grid item xs={12} md={6} >
        <Paper elevation={1} sx={{padding: '10px 0px 135px 20px'}}>
          <h1>{t("dashboard.campaignUser.statistics")}</h1>
          <div>
            <h4>{t("dashboard.campaignUser.totalPts")} {points} </h4>
            {
              currentLevel?.nextLevel != -1 &&
              <h4>{t("dashboard.campaignUser.nextLevel")} {currentLevel?.nextLevel || 0} {t("dashboard.campaignUser.pts")}</h4>
            }
            <h4>{t("dashboard.campaignUser.addedVoters")} {votersAdded} </h4>
            <h4>{t("dashboard.campaignUser.confirmedVoters")} {votersConfirmed}</h4>
          </div>
        </Paper >
      </Grid>
      

      <Grid item xs={12} >
        <Leaderboard rows={leaderboards}></Leaderboard>
      </Grid>

    </Grid> 
  )
    
};
  
  export default CampaignUserDashboard;