import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';

import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ChipDropdown from './ChipDropdown';
import { useForm } from "react-hook-form"
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Confirmado',
  'Pendiente',
  'Negado',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SearchFilter({onSubmitCallback, onSearchByChange, isLoading = false}) {
  const { t } = useTranslation(); 
  const theme = useTheme();
  //const [value, setValue] = React.useState([]);
  const [dropdownValue, setDropdownValue] = React.useState("Cedula");
  
  const form = useForm({
    defaultValues: {
      first_name: "",
      last_name_1: "",
      last_name_2: "",
      identifier: ""
    },
  })
  
  const { 
    handleSubmit, 
    register,
    formState,
    watch,
    setValue
  } = form;

  const {errors} = formState;

  /*const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };*/

  const onSubmit = (event) => {
    onSubmitCallback && onSubmitCallback(dropdownValue, event);
    console.log(event);
  };

  const handleDropdownChange = (value) =>{
    setDropdownValue(value);
    if(value === 'Nombre'){
      setValue('identifier', '');
    }
    else{
      setValue('first_name', '');
      setValue('last_name_1', '');
      setValue('last_name_2', '');
    }
    onSearchByChange && onSearchByChange(value);
  }

  return (
    <FormControl >
      <Card variant="outlined" sx={{padding:'20px 20px', backgroundColor: 'rgba(239, 239, 239, 0.2)'}}>
        <Box component="form"  onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <ChipDropdown 
            values={ [ 
                {"value": "Cedula", "label": "Cedula", "color":"success"},
                {"value": "Nombre", "label": "Nombre","color":"warning"}
              ]
            }
            defaultValue={"Cedula"}
            onChange={handleDropdownChange}
            label={t('searchFilter.searchFor')}
          />
          <Grid container spacing={2}>
            {dropdownValue === "Cedula" && (
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  autoComplete='off'
                  fullWidth
                  id="identifier"
                  label={t('searchFilter.id')}
                  name="cedula"
                  required
                  {...register("identifier", { required: "Identifier is required." })}
                  error={!!errors.identifier}
                  helperText={errors.identifier?.message}
                  style={{backgroundColor:"#fff"}}
                />
              </Grid> )
            }

            {dropdownValue !== "Cedula" && (
              <>
                <Grid item md={4} xs={12}>
                  <TextField
                    margin="normal"
                    autoComplete='off'
                    required
                    fullWidth
                    id="first_name"
                    label={t('searchFilter.name')}
                    name="first_name"
                    autoFocus
                    {...register("first_name", { required: "First name is required." })}
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message}
                    style={{backgroundColor:"#fff"}}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    margin="normal"
                    autoComplete='off'
                    required
                    fullWidth
                    id="last_name_1"
                    label={t('searchFilter.lastName1')}
                    name="last_name_1"

                    autoFocus
                    style={{backgroundColor:"#fff"}}
                    {...register("last_name_1", { required: "Last name is required." })}
                    error={!!errors.last_name_1}
                    helperText={errors.last_name_1?.message}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    margin="normal"
                    autoComplete='off'
                    fullWidth
                    id="last_name_2"
                    label={t('searchFilter.lastName2')}
                    name="last_name_2"
                    autoFocus
                    required
                    style={{backgroundColor:"#fff"}}
                    {...register("last_name_2")}
                   
                  />
                </Grid>
              </>
              )
            }
            <Grid item xs={6}>
              {!isLoading && <Button type="submit" variant='contained' >{t('searchFilter.btnSearch')}</Button>}
              {isLoading && <CircularProgress />}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </FormControl>
  );
}

SearchFilter.propTypes = {
    children: PropTypes.node,
    onSubmitCallback: PropTypes.func,
    onSearchByChange: PropTypes.func
};

SearchFilter.defaultProps = {
  onSearchByChange: null,
  onSubmitCallback: null,
};