import { ROOT_URL } from "../environment";

// POST -> Register User
export const USERS_ENDPOINT = `${ROOT_URL}/users`;

// POST -> Authentication
export const LOGIN_ENDPOINT = `${ROOT_URL}/login`;

export const POLITICAL_PARTY_ENDPOINT = `${ROOT_URL}/political_parties`;

export const CAMPAIGNS_ENDPOINT = `${ROOT_URL}/campaigns`;

export const SUPER_ADMIN_ENDPOINT = `${ROOT_URL}/super_admin`;


// GET -> Voters Catalog
export const CATALOG_VOTERS_ENDPOINT = `${ROOT_URL}/catalog_voters`;

// POST -> Add new Voter entry
export const VOTERS_ENDPOINT = `${ROOT_URL}/voters`;

// POST -> Add new Voter entry
export const CALL_CENTER_ENDPOINT = `${ROOT_URL}/call_centers`;


// GET CALLS FOR DROPDOWNS
export const COUNTRIES_ENDPOINT = `${ROOT_URL}/countries`;
export const PROVINCES_ENDPOINT = `${ROOT_URL}/provinces`;
export const CANTONS_ENDPOINT = `${ROOT_URL}/cantons`;
export const DISTRICTS_ENDPOINT = `${ROOT_URL}/districts`;

// DASHBOARDS ENPOINT
export const DASHBOARDS_ENDPOINT = `${ROOT_URL}/dashboards`;

export const ACTIVATIONS_ENDPOINT = `${ROOT_URL}/account_activations`;