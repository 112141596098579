import PropTypes from 'prop-types';
import * as React from 'react';
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import { Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useEffect, useState } from 'react';
import {
  Paper,
  Button 
} from '@mui/material';
import { getCall, postCall } from '../../helpers/apiManager';
import { SUPER_ADMIN_ENDPOINT } from '../../routes';
import { isEmpty } from 'lodash';
import CircularLoader from '../common/CircularLoader';
import { ROOT_WS } from '../../environment';




const SuperAdminTools = ({ children  }) => {
  const ws = new WebSocket(`${ROOT_WS}/cable`);
  const [campaignGenerationMessage, setCampaignGenerationMessage] = useState("");
  const [populateCrMessage, setPopulateCrMessage] = useState("");
  const [guid, setGuid] = useState("");

  ws.onopen = () => {
    console.log("Connected to websocket server");
    setGuid(Math.random().toString(36).substring(2,15));

    ws.send(
      JSON.stringify({
        command: "subscribe",
        identifier: JSON.stringify({
          id: guid,
          channel: "InternalToolsChannel"
        })
      })
    )
  }

  ws.onmessage = (e) => {
    const data = JSON.parse(e.data);
    if(data.type === "ping") return;
    if(data.type === "welcome") return;
    if(data.type === "confirm_subscription") return;
    if(data.type === "disconnect") return;

    if(data?.message?.func === "create_demo_campaign"){
      setCampaignGenerationMessage(data.message.message);
    }

    if(data?.message?.func === "populate_cr"){
      setPopulateCrMessage(data.message.message);
    }

    console.log(data);
  }

  const [isPopulatingCR, setIsPopulatingCR] = useState(false);
  const [isGeneratingDemoData, setIsGeneratingDemoData] = useState(false);
  const [isDeletingDemoData, setIsDeletingDemoData] = useState(false);

  const [demoDataResults, setDemoDataResults ] = useState("");
  const [deleteDataResults, setDeleteDataResults ] = useState("");
  const [populateCRResults, setPopulateCRResults ] = useState("");

  const onGenerateDemoData = async () =>{
    await setDemoDataResults("")
    await setIsGeneratingDemoData(true);
    const data = await getCall(`${SUPER_ADMIN_ENDPOINT}/generate_demo_campaign`);
    setDemoDataResults(JSON.stringify(data, null, 2));
    await setIsGeneratingDemoData(false);
  }

  const onDeleteDemoData = async () =>{
    await setDeleteDataResults("");
    await setIsDeletingDemoData(true);
    const data = await getCall(`${SUPER_ADMIN_ENDPOINT}/delete_demo_campaign`);
    setDeleteDataResults(JSON.stringify(data, null, 2));
    await setIsDeletingDemoData(false);
  }

  const onPopulateCRImport = async () => {
    await setPopulateCRResults("")
    await setIsPopulatingCR(true);
    const data = await postCall(`${SUPER_ADMIN_ENDPOINT}/populate_cr`, {});
    await setPopulateCRResults(JSON.stringify(data, null, 2));
    await setIsPopulatingCR(false);
  }
  



  return(
    <AdminLayout>
      <Container component="main" >
        <CssBaseline />

        <Paper elevation={2} style={{padding: '20px', margin: '10px 0'}}>
          <h4>Guid: {guid}</h4>
        </Paper>

        <Paper elevation={2} style={{padding: '20px', margin: '10px 0'}}>
          <h3>Eliminar Campaña de prueba</h3>
          <h5>Este proceso puede tomar varios minutos y se borrarán todos los datos existentes en la campaña GCP.</h5>
          
          {!isEmpty(deleteDataResults) && <h4>Resultados:</h4>}
          {!isEmpty(deleteDataResults) && <pre>{deleteDataResults}</pre>}
          
          {!isDeletingDemoData  ? 
            <Button color="error" type="submit" onClick={onDeleteDemoData} variant="contained" sx={{ mt: 3, mb: 2 }}>
              Eliminar Campaña
            </Button>
            :
            <div style={{textAlign: 'center'}}><CircularLoader /><h5>Procesando los datos, por favor espere...</h5> </div>
          }
        </Paper>


        <Paper elevation={2} style={{padding: '20px'}}>
          <h3>Generar Campaña de prueba</h3>
          <h5>Este proceso puede tomar varios minutos y se borrarán todos los datos existentes en la campaña GCP.</h5>
          
          {!isEmpty(demoDataResults) && <h4>Resultados:</h4>}
          {!isEmpty(demoDataResults) && <pre>{demoDataResults}</pre>}
          
          {!isGeneratingDemoData  ? 
            <Button color="success" type="submit" onClick={onGenerateDemoData} variant="contained" sx={{ mt: 3, mb: 2 }}>
              Generar Campaña
            </Button>
            :
            <div style={{textAlign: 'center'}}>
              <CircularLoader />
              <h5>Procesando los datos, por favor espere...</h5> 
              <h5>{campaignGenerationMessage}</h5>     
            </div>
          }
        </Paper>

        <Paper elevation={2} style={{padding: '20px', margin: '10px 0'}}>
          <h3>Importar datos del padrón de Costa Rica</h3>
          <h5>Este proceso puede tomar varios minutos y se inactivarán los usuarios que no se encuentren en la lista.</h5>
          
          {!isEmpty(demoDataResults) && <h4>Resultados:</h4>}
          {!isEmpty(demoDataResults) && <pre>{demoDataResults}</pre>}
          
          {!isPopulatingCR  ? 
            <Button color="success" type="submit" onClick={onPopulateCRImport} variant="contained" sx={{ mt: 3, mb: 2 }}>
              Importar Datos
            </Button>
            :
            <div style={{textAlign: 'center'}}>
              <CircularLoader />
              <h5>Importando datos, por favor espere...</h5> 
              <h5>{populateCrMessage}</h5>     
            </div>
          }
        </Paper>

      </Container>
    </AdminLayout>
  )

}

SuperAdminTools.propTypes = {

};

export default SuperAdminTools;
