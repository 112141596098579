import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { LOGIN_ENDPOINT } from '../../routes';
import { postCall } from '../../helpers/apiManager';
import { AuthLayout  } from '../../layouts/auth/AuthLayout';
import { useForm, Controller } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import {  useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import ThemeContext from '../../context/themeContext';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


function Copyright(props) {
  const { t, i18n } = useTranslation(); 

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {t('copyright')}
      <Link color="inherit" href="#">
      On Latam
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const Login = () =>{
  const [alertMessage, setAlertMessage] = useState("");
  
  const theme = useContext(ThemeContext);

  const { login, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); 

  const form = useForm({
    defaultValues: {
      email: "",
      password: ""
    },
  })

  const { 
    handleSubmit, 
    register,
    formState,
    watch
  } = form;

  const {errors} = formState;

  useEffect(
    () => {
      if(isLoggedIn){
        navigate("/main");
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [/*pathname*/]
  );

  const onSubmit = async  (data) => { 
    const results = await postCall(LOGIN_ENDPOINT, {"user": data}, false);
    if(!results.success){
      setAlertMessage(t(results.message));
      
      setTimeout(() => {
        setAlertMessage("");
      }, 8000)

    }
    else{
      if(results.user.colors !== undefined){
        const [primary, secondary] = results.user.colors;
        theme.setColors({
          palette: {
            primary: {
              main: primary,
            },
            secondary: {
              main: secondary,
            },
          },
        })
      }
      login(results);
      //alert("Login successfully");
    }
    console.log(results);
  }

  return(
    <AuthLayout>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        {
        !isEmpty(alertMessage) && <Alert icon={<CheckIcon fontSize="inherit" />} severity="error">
          <AlertTitle>{alertMessage}</AlertTitle>
        </Alert>
        }
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('login.signin')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('common.textfield.email')}
              name="email"
              autoComplete="email"
              autoFocus
              {...register("email")}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('common.textfield.password')}
              type="password"
              id="password"
              autoComplete="current-password"
              {...register("password")}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t('login.remember')}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('login.btnSignIn')}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  {t('login.forgotPassword')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </AuthLayout>
    )
  };

export default Login;