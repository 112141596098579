import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { postCall } from '../../helpers/apiManager';
import { AuthLayout  } from '../../layouts/auth/AuthLayout';
import { useForm, Controller } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import {  useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import ThemeContext from '../../context/themeContext';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import CircularLoader from '../common/CircularLoader';
import { USERS_ENDPOINT, ACTIVATIONS_ENDPOINT } from '../../routes';
import { getCall, putCall } from '../../helpers/apiManager';
import { ROOT_URL } from '../../environment';

const AccountActivation = () =>{
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdate, setShowUpdate] = useState(true);
  const [user, setUser] = useState({});

  const theme = useContext(ThemeContext);


  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); 

  const form = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: ""
    },
  })

  const { 
    handleSubmit, 
    register,
    formState,
    watch,
    setValue,
    getValues
  } = form;

  const {errors} = formState;

  const activateUser = async () => {
    const url = window.location.pathname;
    const search = window.location.search;
    const query = ROOT_URL+`${url}${search}`

    const results = await getCall(query,{},false);
    debugger;
    if(results.success){
      await setShowUpdate(true);
      await setUser(results.user);
    }
    else{
      await setShowUpdate(false);
    }
  }

  useEffect( 
    () => {
     activateUser();
  }, []);

  const onSubmit = async  (data) => { 
    const userData = {"user": {...data, ...user}};

    const results = await putCall(ACTIVATIONS_ENDPOINT+'/update_first_password',userData , false);
    if(!results.success){
      setAlertMessage(t(results.message));
      setTimeout(() => {
        setAlertMessage("");
      }, 8000)
    }

    setTimeout(() => {
      setAlertMessage("La contraseña se ha actualizado exitosamente. Puede utilizar las nuevas credenciales para iniciar la sesión.");
    }, 8000)
    navigate("/log-in");
    
    console.log(results);
  }

  const renderPasswordFields = () =>(
    <>
      <Grid item xs={12}>
        
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('common.textfield.password')}
          type="password"
          id="password"
          autoComplete="current-password"
          {...register("password", { required: "Password is required" })}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password_confirmation"
          label={t('common.textfield.passwordConfirm')}
          type="password"
          id="password_confirmation"
          autoComplete="password_confirmation"
          error={!!errors.password_confirmation}
          helperText={errors.password_confirmation?.message}
          {...register("password_confirmation", {
            required: true,
            validate: (val) => {
              if (watch('password') !== val) {
                return t('common.textfield.password.error');
              }
            },
            })}
        />
      </Grid>
    </>
  );


  return(
    <>
      <Container component="main" >
        <CssBaseline />
        {
        !isEmpty(alertMessage) && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          <AlertTitle>{alertMessage}</AlertTitle>
        </Alert>
        }
        <Box
        sx={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >     
      <Typography component="h1" variant="h5">
        {t("accountActivation.title")}
      </Typography>
     
      {showUpdate &&
        (<><Typography component="h4" variant="h7">
            {t("accountActivation.activated")}
          </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate >
          <Grid container xs={12} spacing={2}>
            {renderPasswordFields()}
          </Grid>
          {isLoading && <CircularLoader /> }
          {
            !isLoading && <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('account.btnUpdate')}
            </Button>
          }
          
        </Box>
        </>
        )
      }
    </Box>
      
      </Container>
    </>
  )
};

export default AccountActivation;