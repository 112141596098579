import i18next from 'i18next';
//import 'intl-pluralrules';

// import LanguageDetector from 'i18next-browser-languagedetector';
// import { reactI18nextModule } from 'react-i18next';
import { initReactI18next } from 'react-i18next';

import en from '../translations/en.json';
import es from '../translations/es.json';

export async function getLanguage() {
  let language = 'es';
  if (language === null) {
    language = 'es';
  }
  i18next.changeLanguage(language);
  return language;
}

export async function setLanguage(newLanguage) {
    i18next.changeLanguage(newLanguage);
}

  i18next
  // Plug in i18next React extensions.
  .use(initReactI18next)
  .init({
    
    
    resources: {
        "en": {translation: en},
        "es": {translation: es},
    },
    lng: "es",
    // Disable i18next's default escaping, which prevents XSS
    // attacks. React already takes care of this.
    interpolation: {
      escapeValue: false,
    },
  });

  //const lang = getLanguage();

export default i18next;
