export const backendCall = async (method, url, data = {}, includeCredentials = true) => {
  let results = null;
  try {
    let params = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };
    if (method !== 'GET') {
      params['body'] = JSON.stringify(data);
    }
    else{
      url = url +"?"+ new URLSearchParams(data);
    }
    if (includeCredentials){
      const userLS = localStorage.getItem('user');
      const user = JSON.parse(userLS);
      if(user == null){
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('user');
      }
      params['headers']['Authorization'] = `Bearer ${(user['token'])}`
    }
    let result = await fetch(url, params);
    results = await result.json();
  }
  catch (e) {
    console.log(e);
  }
  return results;
}

export const postCall = async (url, data = {}, includeCredentials = true) => {
  return backendCall('post', url, data, includeCredentials);
}

export const getCall = async (url, data = {}, includeCredentials = true) => {
  return backendCall('GET', url, data, includeCredentials);
}

export const putCall = async (url, data = {}, includeCredentials = true) => {
  return backendCall('put', url, data, includeCredentials);
}

export const deleteCall = async (url, data = {}, includeCredentials = true) => {
  return backendCall('delete', url, data, includeCredentials);
}