import { useCallback, useEffect, useState } from 'react';
//import { useRouter } from 'next/navigation';
import PropTypes from 'prop-types';
import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
//import { useAuth } from '../../hooks/use-auth';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const AccountPopover = (props) => {
  const { t } = useTranslation(); 
  const { anchorEl, onClose, open } = props;
  const {logout, getLoggedUserAttr} = useAuth();
  const navigate = useNavigate();
  const [name, setName] = useState("Loading...");

  useEffect(() => {
    const fillProfile = async () => {
      const fullName = await getLoggedUserAttr('full_name');
      await setName(fullName)
    }
    fillProfile();
  }, []);

  
  const handleSignOut = useCallback(
    () => {
      onClose?.();
      logout();
    },
    [onClose]
  );

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2
        }}
      >
        <Typography variant="overline">
          {t("accountPopover.account")}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {name}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1
          }
        }}
      >
        <MenuItem onClick={handleSignOut}>
          {t("accountPopover.signOut")}
        </MenuItem>
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
