

import {Routes, Route, Link} from 'react-router-dom';
import Login from '../Login/';
import SignUp from '../SignUp/';
import Dashboard from '../Dashboard/';
import PersistentDrawerLeft from '../common/PersistentDrawerLeft';
import AdminRegistration from '../Registration/AdminRegistration';
import CommonTable from '../common/CommonTable';
import DataEntry from '../DataEntry';
import Voter from '../Voter';
import CatalogVoters from '../CatalogVoters';
import Registration from '../Registration/Registration';
import { ProtectedRoute,SuperAdminRoute } from '../ProtectedRoute/ProtectedRoute';
import PoliticalParty from '../PoliticalParty';
import  useAuth  from '../../hooks/useAuth';
import Campaigns from '../Campaigns/Campaigns';
import React from 'react';
import CircularLoader from '../common/CircularLoader';
import ConfirmVoters from '../CallCenterConfirm/ConfirmVoters';
import SuperAdminTools from '../SuperAdmin/SuperAdminTools';
import NotFound from '../NotFound/NotFound';
import Account from '../Account/Account';
import AccountActivation from '../Account/AccountActivation';

function SiteRoutes() {
  //const {login} = useAuth();
  return (
    <React.Suspense fallback={<CircularLoader />}>
      <Routes>
        <Route path="/"  element={<Dashboard />}/>
        <Route path="/account_activations/*" element={<AccountActivation />} />
        <Route path="/sign-up"  element={<SignUp />}/>
        <Route path="/log-in"  element={<Login />}/>
        <Route path="/404"  element={<NotFound />}/>      
        
        <Route element={<ProtectedRoute />}>
          <Route path="/drawer"  element={<PersistentDrawerLeft />}/>
          <Route path="/main"  element={<Dashboard />}/>
          <Route path='/auth/register'  element={<AdminRegistration />}/>
          <Route path='/dashboard'  element={<Dashboard />}/>
          <Route path="/tableExample"  element={<CommonTable />}/>
          <Route path="/admin/data-entry"  element={<DataEntry />}/>
          <Route path="/dataEntry"  element={<DataEntry />}/>
          <Route path="/admin/voter"  element={<Voter />}/>
          <Route path="/voters"  element={<CatalogVoters />}/>
          <Route path="/confirm-voters"  element={<ConfirmVoters />}/>
          <Route path="/admin/political-parties"  element={<PoliticalParty />}/>
          <Route path="/admin/campaigns"  element={<Campaigns />}/>
          <Route path="/account"  element={<Account />}/>

        </Route>

        <Route element={<SuperAdminRoute />}>
          <Route path="/super-admin-tools"  element={<SuperAdminTools />}/>
        </Route>

        <Route path="*"  element={<NotFound />}/>

      </Routes>
    </React.Suspense>
  );
}

export default SiteRoutes;
