import { 
  campaignAdminItems,
  productAdminItems,
  applicationSuperAdminItems,
  campaignUserItems,
  callCenterItems
} from "../layouts/admin/config"

export const roleNavItems = [
  {
    id: 1,
    name: "Application Super Admin",
    items: applicationSuperAdminItems
  },
  {
    id: 2,
    name: "Product Admin",
    items: productAdminItems
  },
  {
    id: 3,
    name: "Campaign Admin",
    items: campaignAdminItems
  },
  {
    id: 4,
    name: "Campaign User",
    items: campaignUserItems
  },
  {
    id: 5,
    name: "Call Center User",
    items: callCenterItems
  }
]

export const getNavBarItems = (roleId) => {
  let elements = null;
  for(let i = 0; i < roleNavItems.length; i++){
    if(roleId === roleNavItems[i].id){
      elements = roleNavItems[i].items;
      break;
    }
  }
  return elements;
}

export const APPLICATION_SUPER_ADMIN = 1;
export const PRODUCT_ADMIN = 2;
export const CAMPAIGN_ADMIN = 3;
export const CAMPAIGN_USER = 4;
export const CALL_CENTER_USER = 5;

